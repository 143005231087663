import { Fragment, useEffect, useState } from 'react';
import { Listbox, Menu, Transition } from '@headlessui/react';
import MDEditor from '@uiw/react-md-editor';
import {
  CheckIcon,
  ChevronDownIcon,
  DotsVerticalIcon,
  PencilIcon,
  TrashIcon,
} from '@heroicons/react/solid';

import { classNames } from '../../../utils/parseUtils';
import { useDebouncedEffect } from '../../../utils/useDebouncedEffect';

const TASK_STATUS = ['Sin empezar', 'En progreso', 'Completado'];

export default function TaskItem({
  task,
  costPerHour = 30,
  projectStatus = 'En cotización',
  onStatusChange,
  onPercentageChange,
  onDetailsClick,
  onEditClick,
  onDeleteClick,
}) {
  const [showFullDescription, setShowFullDescription] = useState(false);
  const [percentage, setPercentage] = useState(task.percentage || 0);

  const [firstLoading, setFirstLoading] = useState(true);

  useEffect(() => {
    setPercentage(task.percentage || 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [task]);

  useDebouncedEffect(
    () => {
      if (!firstLoading) onPercentageChange(percentage);
      else setFirstLoading(false);
    },
    1500,
    [percentage]
  );

  return (
    <li className="flex flex-col justify-between bg-white shadow sm:rounded-lg">
      <div>
        <div className="flex items-center justify-between pl-6 pr-2">
          <div className="flex-1 pt-5 truncate">
            <h3
              className="text-sm font-medium text-gray-900 truncate"
              title={task.name}
            >
              {task.name}
            </h3>

            <p className="mt-1 text-sm text-gray-500 truncate">
              <span className="mr-2">
                {task.time} horas{' '}
                {projectStatus === 'En cotización' && (
                  <span>- S/ {task.time * costPerHour}</span>
                )}
              </span>
              <span
                className={classNames(
                  task.difficulty === 'Difícil'
                    ? 'text-red-800 bg-red-100'
                    : task.difficulty === 'Intermedio'
                    ? 'text-yellow-800 bg-yellow-100'
                    : 'text-green-800 bg-green-100',
                  'flex-shrink-0 inline-block px-2 py-0.5 text-xs font-medium rounded-full'
                )}
              >
                {task.difficulty}
              </span>
            </p>
          </div>
          <Menu as="div" className="relative inline-block text-left">
            {({ open }) => (
              <>
                <div>
                  <Menu.Button className="inline-flex items-center justify-center w-8 h-8 text-gray-400 bg-transparent bg-white rounded-full hover:text-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500">
                    <span className="sr-only">Open options</span>
                    <DotsVerticalIcon className="w-5 h-5" aria-hidden="true" />
                  </Menu.Button>
                </div>
                <Transition
                  show={open}
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items
                    static
                    className="absolute right-0 z-10 w-56 p-1 mt-2 origin-top-right bg-white divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                  >
                    <Menu.Item>
                      {({ active }) => (
                        <button
                          className={`${
                            active
                              ? 'bg-primary-500 text-white'
                              : 'text-gray-900'
                          } group flex rounded-md items-center w-full px-2 py-2 text-sm`}
                          onClick={onEditClick}
                        >
                          <PencilIcon
                            className={`${
                              active ? 'text-gray-200' : 'text-gray-400'
                            } w-5 h-5 mr-2`}
                            aria-hidden="true"
                          />
                          Editar
                        </button>
                      )}
                    </Menu.Item>
                    <Menu.Item>
                      {({ active }) => (
                        <button
                          className={`${
                            active ? 'bg-red-500 text-white' : 'text-gray-900'
                          } group flex rounded-md items-center w-full px-2 py-2 text-sm`}
                          onClick={onDeleteClick}
                        >
                          <TrashIcon
                            className={`${
                              active ? 'text-gray-100' : 'text-gray-400'
                            } w-5 h-5 mr-2`}
                            aria-hidden="true"
                          />
                          Eliminar
                        </button>
                      )}
                    </Menu.Item>
                  </Menu.Items>
                </Transition>
              </>
            )}
          </Menu>
        </div>
        <div
          className={classNames(
            showFullDescription ? '' : 'truncate',
            projectStatus !== 'En cotización' ? '' : 'pb-5',
            'prose mt-3 text-sm text-gray-500 px-6 '
          )}
        >
          {showFullDescription ? (
            <>
              <MDEditor.Markdown source={task.description} />
              <button
                className="block text-sm text-gray-400 underline cursor-pointer hover:text-gray-600 focus:text-gray-600 focus:outline-none"
                onClick={() => setShowFullDescription(false)}
              >
                Ocultar
              </button>
            </>
          ) : (
            <button
              className="block text-sm text-gray-400 underline cursor-pointer hover:text-gray-600 focus:text-gray-600 focus:outline-none"
              onClick={onDetailsClick}
            >
              Mostrar descripción
            </button>
          )}
        </div>
      </div>
      {projectStatus !== 'En cotización' && (
        <div className="px-6 pb-5">
        <div className="mt-3">
          <input
            className="w-full h-2 bg-gray-300 rounded-lg appearance-none slider-thumb"
            type="range"
            name="percentage"
            step="25"
            min="0"
            max="100"
            value={percentage}
            onChange={(event) => setPercentage(event.target.value)}
          />
        </div>
          <div
            className={classNames(
              projectStatus !== 'En cotización' ? 'xl:justify-center' : '',
              'relative z-0 xl:flex items-center w-full mt-2'
            )}
          >
            <Listbox value={task.status} onChange={onStatusChange}>
              {({ open }) => (
                <>
                  <Listbox.Label className="sr-only">
                    Change published status
                  </Listbox.Label>
                  <div className="relative">
                    <div className="inline-flex w-full divide-x rounded-md shadow-sm divide-primary-700">
                      <div className="relative z-0 inline-flex w-full divide-x rounded-md shadow-sm divide-primary-700">
                        <div className="relative inline-flex items-center justify-center w-full py-2 pl-3 pr-4 text-white border border-transparent shadow-sm bg-primary-600 rounded-l-md">
                          <CheckIcon className="w-5 h-5" aria-hidden="true" />
                          <p className="ml-2.5 text-sm font-medium">
                            {task.status}
                          </p>
                        </div>
                        <Listbox.Button className="relative inline-flex items-center p-2 text-sm font-medium text-white bg-primary-600 hover:bg-primary-700 focus:outline-none focus:z-10 focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-50 focus:ring-primary-600 rounded-r-md">
                          <span className="sr-only">
                            Change published status
                          </span>
                          <ChevronDownIcon
                            className="w-5 h-5 text-white"
                            aria-hidden="true"
                          />
                        </Listbox.Button>
                      </div>
                    </div>

                    <Transition
                      show={open}
                      as={Fragment}
                      enter="transition ease-out duration-200"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-150"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Listbox.Options
                        static
                        className="absolute right-0 w-48 mb-2 -ml-1 overflow-hidden origin-bottom-right bg-white divide-y divide-gray-200 rounded-md shadow-lg bottom-full ring-1 ring-black ring-opacity-5 focus:outline-none sm:left-auto sm:right-0"
                      >
                        {TASK_STATUS.map((option) => (
                          <Listbox.Option
                            key={option}
                            className={({ active }) =>
                              classNames(
                                active
                                  ? 'text-white bg-primary-600'
                                  : 'text-gray-900 bg-white',
                                'cursor-pointer select-none relative px-4 py-2 sm:py-3 text-sm'
                              )
                            }
                            value={option}
                          >
                            {({ selected, active }) => (
                              <div className="flex flex-col">
                                <div className="flex justify-between">
                                  <p
                                    className={
                                      selected ? 'font-semibold' : 'font-normal'
                                    }
                                  >
                                    {option}
                                  </p>
                                  {selected ? (
                                    <span
                                      className={
                                        active
                                          ? 'text-white'
                                          : 'text-primary-500'
                                      }
                                    >
                                      <CheckIcon
                                        className="w-5 h-5"
                                        aria-hidden="true"
                                      />
                                    </span>
                                  ) : null}
                                </div>
                              </div>
                            )}
                          </Listbox.Option>
                        ))}
                      </Listbox.Options>
                    </Transition>
                  </div>
                </>
              )}
            </Listbox>

            <div className="px-2 py-1.5 flex-1 text-white rounded-md bg-primary-600 ml-4 text-center hidden xl:block">
              {percentage}%
            </div>
          </div>
        </div>
      )}
    </li>
  );
}
