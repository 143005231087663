import highlightText from '@brianmcallister/highlight-text';
import { constants } from '../../utils/constants';

export default function UserCard({
  user,
  searchWords,
  onEditClick,
  onDetailsClick,
}) {
  return (
    <li className="col-span-1 bg-white divide-y divide-gray-200 shadow sm:rounded-lg">
      <div className="flex items-center justify-between w-full px-6 py-4 space-x-6">
        <div className="flex-1 truncate">
          <div className="flex items-center space-x-3">
            <h3
              className="text-sm font-medium text-gray-900 truncate"
              dangerouslySetInnerHTML={{
                __html: highlightText(
                  `${user.firstName} ${user.lastName}`,
                  searchWords
                ),
              }}
            ></h3>
          </div>
          <p className="flex items-center mt-2 text-sm text-gray-500">
            <svg
              className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              aria-hidden="true"
            >
              <path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z" />
              <path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z" />
            </svg>
            <span
              className="truncate"
              dangerouslySetInnerHTML={{
                __html: highlightText(user.email, searchWords),
              }}
            ></span>
          </p>
        </div>
        <span
          className={`${
            user.isActive
              ? 'text-green-800 bg-green-100'
              : 'text-red-800 bg-red-100'
          } flex-shrink-0 inline-block px-2 py-0.5 text-xs font-medium rounded-full`}
        >
          {constants.USER_POSITIONS_DISPLAY[user.position]}
        </span>
      </div>
      <div>
        <div className="flex -mt-px divide-x divide-gray-200">
          <div className="flex flex-1 w-0">
            <button
              onClick={() => {
                onEditClick(user);
              }}
              className="inline-flex items-center justify-center flex-1 w-0 py-3 -mr-px text-sm font-medium text-gray-700 border border-transparent rounded-bl-lg hover:text-gray-500"
            >
              {/* Heroicon name: mail */}
              <svg
                className="w-5 h-5 text-gray-400"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"
                />
              </svg>
              <span className="ml-3">Editar</span>
            </button>
          </div>
          <div className="flex flex-1 w-0 -ml-px">
            <button
              onClick={() => {
                onDetailsClick(user);
              }}
              className="inline-flex items-center justify-center flex-1 w-0 py-3 text-sm font-medium text-gray-700 border border-transparent rounded-br-lg hover:text-gray-500"
            >
              {/* Heroicon name: phone */}

              <svg
                className="w-5 h-5 text-gray-400"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                />
              </svg>
              <span className="ml-3">Detalles</span>
            </button>
          </div>
        </div>
      </div>
    </li>
  );
}
