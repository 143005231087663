import { constants } from '../../utils/constants';
import StorageService from '../../utils/storage';
import SidebarItem from './SidebarItem';
import SidebarItemParent from './SidebarItemParent';

const SIDEBAR_ITEMS = [
  {
    enabled: false,
    name: 'dashboard',
    path: '/dashboard',
    title: 'Inicio',
    icon: (
      <svg
        className="w-6 h-6 mr-3 text-gray-500 group-hover:text-gray-700"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
        aria-hidden="true"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6"
        />
      </svg>
    ),
  },
  {
    enabled: true,
    name: 'projects',
    path: '/projects',
    title: 'Proyectos',
    icon: (
      <svg
        className="w-6 h-6 mr-3 text-gray-500 group-hover:text-gray-700"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-3 7h3m-3 4h3m-6-4h.01M9 16h.01"
        />
      </svg>
    ),
  },
  {
    enabled: true,
    name: 'users',
    path: '/users',
    title: 'Empleados',
    icon: (
      <svg
        className="w-6 h-6 mr-3 text-gray-500 group-hover:text-gray-700"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
        aria-hidden="true"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M12 4.354a4 4 0 110 5.292M15 21H3v-1a6 6 0 0112 0v1zm0 0h6v-1a6 6 0 00-9-5.197M13 7a4 4 0 11-8 0 4 4 0 018 0z"
        />
      </svg>
    ),
  },
  {
    enabled: false,
    name: 'inventory',
    title: 'Inventario',
    icon: (
      <svg
        className="w-6 h-6 mr-3 text-gray-500 group-hover:text-gray-700"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M20 13V6a2 2 0 00-2-2H6a2 2 0 00-2 2v7m16 0v5a2 2 0 01-2 2H6a2 2 0 01-2-2v-5m16 0h-2.586a1 1 0 00-.707.293l-2.414 2.414a1 1 0 01-.707.293h-3.172a1 1 0 01-.707-.293l-2.414-2.414A1 1 0 006.586 13H4"
        />
      </svg>
    ),
    items: [
      {
        enabled: false,
        name: 'categories',
        path: '/categories',
        title: 'Categorías',
        icon: (
          <svg
            className="w-6 h-6 mr-3 text-gray-500 group-hover:text-gray-700"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M7 7h.01M7 3h5c.512 0 1.024.195 1.414.586l7 7a2 2 0 010 2.828l-7 7a2 2 0 01-2.828 0l-7-7A1.994 1.994 0 013 12V7a4 4 0 014-4z"
            />
          </svg>
        ),
      },
      {
        enabled: false,
        name: 'subcategories',
        path: '/subcategories',
        title: 'Subcategorías',
        icon: (
          <svg
            className="w-6 h-6 mr-3 text-gray-500 group-hover:text-gray-700"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M7 7h.01M7 3h5c.512 0 1.024.195 1.414.586l7 7a2 2 0 010 2.828l-7 7a2 2 0 01-2.828 0l-7-7A1.994 1.994 0 013 12V7a4 4 0 014-4z"
            />
          </svg>
        ),
      },
    ],
  },
  {
    enabled: true,
    name: 'settings',
    path: '/settings',
    title: 'Configuración',
    icon: (
      <svg
        className="w-6 h-6 mr-3 text-gray-500 group-hover:text-gray-700"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z"
        />
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
        />
      </svg>
    ),
  },
];

export default function SidebarItems({ className, onCloseClick }) {
  const position = StorageService.get('userData').position;

  const sidebarItems = constants.SIDEBAR_ITEMS_PERMISSIONS.filter(
    (obj) => position === 'super_admin' || obj.position === position
  );

  function hasAccessToParent(items) {
    return items.some((obj) =>
      sidebarItems.some((obj2) => obj2.name === obj.name)
    );
  }

  return (
    <nav className={className}>
      {SIDEBAR_ITEMS.map((obj) => {
        if (!obj.enabled) return null;
        if (obj.items !== undefined) {
          if (!hasAccessToParent(obj.items)) return null;
          return (
            <SidebarItemParent
              key={obj.name}
              sidebarItem={obj}
              paths={obj.items.map((item) => item.path)}
            >
              {obj.items.map((item) => {
                if (!sidebarItems.some((obj2) => obj2.name === item.name))
                  return null;
                return (
                  <SidebarItem
                    key={item.name}
                    path={item.path}
                    onClick={onCloseClick}
                    isChild={true}
                  >
                    {item.icon}
                    {item.title}
                  </SidebarItem>
                );
              })}
            </SidebarItemParent>
          );
        }
        if (!sidebarItems.some((obj2) => obj2.name === obj.name)) return null;
        return (
          <SidebarItem
            key={obj.name}
            path={obj.path}
            onClick={onCloseClick}
            isChild={false}
          >
            {obj.icon}
            {obj.title}
          </SidebarItem>
        );
      })}
    </nav>
  );
}
