import { Fragment, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import {
  BriefcaseIcon,
  CalendarIcon,
  ClockIcon,
  CashIcon,
  InformationCircleIcon,
  LocationMarkerIcon,
  PencilIcon,
  PlusCircleIcon,
  CheckIcon,
  ChevronDownIcon,
  ClipboardCheckIcon,
} from '@heroicons/react/solid';
import { Listbox, Transition } from '@headlessui/react';
import { useAuth } from '../../../contexts/Auth';

import TabItem from '../../../components/TabItem';
import GeneralApi from '../../../utils/generalApi';
import { showErrorToast, showSuccessToast } from '../../../components/Toast';
import {
  formatDate,
  calcSectionsHours,
  classNames,
} from '../../../utils/parseUtils';
import LoadingSpinner from '../../../components/LoadingSpinner';
import SlideOver from '../../../components/SlideOver';
import TaskCreateForm from '../../../components/Task/TaskCreateForm';
import TaskEditForm from '../../../components/Task/TaskEditForm';
import TaskItem from './TaskItem';
import SectionPage from './SectionPage';
import SectionItem from './SectionItem';
import ProjectEditForm from '../../../components/Project/ProjectEditForm';
import Modal from '../../../components/Modal';
import ConfirmModalContent from '../../../components/ConfirmModalContent';
import MDEditor from '@uiw/react-md-editor';

const PROJECT_STATUS = [
  'En cotización',
  'En progreso',
  'Completado',
  'Pagado',
  'En pausa',
];

export default function ProjectDetails() {
  let history = useHistory();
  let auth = useAuth();

  const { projectId } = useParams();

  const [loading, setLoading] = useState(true);
  const [project, setProject] = useState({});

  // Sections
  const [sections, setSections] = useState([]);
  const [createSectionLoading, setCreateSectionLoading] = useState(false);
  const [sectionName, setSectionName] = useState('');
  const [sectionDescription, setSectionDescription] = useState('');

  // Filters
  const [chosenSection, setChosenSection] = useState('summary');

  const [isTaskCreateOpen, setIsTaskCreateOpen] = useState(false);

  // Delete task
  const [selectedTaskId, setSelectedTaskId] = useState('');
  const [isConfirmDeleteOpen, setIsConfirmDeleteOpen] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);

  // Task details, edit
  const [isTaskDetailsOpen, setIsTaskDetailsOpen] = useState(false);
  const [isTaskEditOpen, setIsTaskEditOpen] = useState(false);
  const [taskDetails, setTaskDetails] = useState({});

  // Edit Project
  const [isProjectEditOpen, setIsProjectEditOpen] = useState(false);

  const generalApi = new GeneralApi(auth, history);

  useEffect(() => {
    getProject();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function getProject() {
    setLoading(true);
    const result = await generalApi.post(`/projects/details`, {
      projectId,
    });
    if (!result.success) {
      setLoading(false);
      showErrorToast(result.message);
      return result;
    }
    setProject(result.data.project);
    setSections(result.data.project.sections);
    setLoading(false);
    return result;
  }

  async function createSection() {
    setCreateSectionLoading(true);
    const result = await generalApi.post(`/projects/section`, {
      projectId,
      name: sectionName,
      description: sectionDescription,
    });
    if (!result.success) {
      setCreateSectionLoading(false);
      showErrorToast(result.message);
      return result;
    }
    getProject();
    setSectionName('');
    setSectionDescription('');
    showSuccessToast('Sección creada');
    setCreateSectionLoading(false);
    return result;
  }

  async function createTask(data) {
    const result = await generalApi.post(`/projects/task`, {
      projectId,
      sectionId: chosenSection,
      ...data,
    });
    if (!result.success) {
      showErrorToast(result.message);
      return result;
    }
    getProject();
    showSuccessToast('Tarea creada');
    setIsTaskCreateOpen(false);
    return result;
  }

  async function updateTask(data) {
    const result = await generalApi.put(`/projects/task`, {
      projectId,
      sectionId: chosenSection,
      taskId: taskDetails._id,
      ...data,
    });
    if (!result.success) {
      showErrorToast(result.message);
      return result;
    }
    getProject();
    showSuccessToast('Tarea modificada');
    setIsTaskEditOpen(false);
    return result;
  }
  async function deleteTask(taskId) {
    setLoadingDelete(true);
    const result = await generalApi.post(`/projects/task/delete`, {
      projectId,
      sectionId: chosenSection,
      taskId,
    });
    if (!result.success) {
      showErrorToast(result.message);
      setLoadingDelete(false);
      return result;
    }
    showSuccessToast('Tarea eliminada');
    setIsConfirmDeleteOpen(false);
    setLoadingDelete(false);
    getProject();
    return result;
  }

  async function updateTaskProgress(taskId, status, percentage) {
    const updateData = {
      projectId,
      sectionId: chosenSection,
      taskId,
    };
    if (status !== null) updateData.status = status;
    if (percentage !== null) updateData.percentage = percentage;
    const result = await generalApi.post(`/projects/task/progress`, updateData);
    if (!result.success) {
      showErrorToast(result.message);
      return result;
    }
    getProject();
    if (status !== null) showSuccessToast('Tarea modificada');
    return result;
  }

  async function updateProject(data) {
    const result = await generalApi.put(`/projects`, {
      ...data,
      projectId: project._id,
    });
    if (!result.success) {
      showErrorToast(result.message);
      return result;
    }
    showSuccessToast('Proyecto modificado');
    setIsProjectEditOpen(false);
    getProject();
    return result;
  }
  async function updateProjectStatus(status) {
    const result = await generalApi.post(`/projects/status`, {
      projectId: project._id,
      status,
    });
    if (!result.success) {
      showErrorToast(result.message);
      return result;
    }
    showSuccessToast('Proyecto modificado');
    getProject();
    return result;
  }

  return (
    <div>
      {/* Slide-over Edit Project */}
      <SlideOver
        isOpen={isProjectEditOpen}
        onClose={() => {
          setIsProjectEditOpen(false);
        }}
      >
        <ProjectEditForm
          project={project}
          toggleFocus={isProjectEditOpen}
          onSubmit={updateProject}
          onCancelClick={() => {
            setIsProjectEditOpen(false);
          }}
        />
      </SlideOver>

      {/* Slide-over Create */}
      <SlideOver
        isOpen={isTaskCreateOpen}
        onClose={() => {
          setIsTaskCreateOpen(false);
        }}
      >
        <TaskCreateForm
          toggleFocus={isTaskCreateOpen}
          onSubmit={createTask}
          onCancelClick={() => {
            setIsTaskCreateOpen(false);
          }}
        ></TaskCreateForm>
      </SlideOver>

      {/* Slide-over Edit */}
      <SlideOver
        isOpen={isTaskEditOpen}
        onClose={() => {
          setIsTaskEditOpen(false);
        }}
      >
        <TaskEditForm
          task={taskDetails}
          toggleFocus={isTaskEditOpen}
          onSubmit={updateTask}
          onCancelClick={() => {
            setIsTaskEditOpen(false);
          }}
        ></TaskEditForm>
      </SlideOver>

      <Modal
        isOpen={isConfirmDeleteOpen}
        onClose={() => {
          setIsConfirmDeleteOpen(false);
        }}
      >
        <ConfirmModalContent
          onClose={() => {
            setIsConfirmDeleteOpen(false);
          }}
          onConfirm={() => {
            deleteTask(selectedTaskId);
          }}
          title="Eliminar tarea"
          content="¿Está seguro que desea eliminar esta tarea?"
          buttonLoading={loadingDelete}
        />
      </Modal>
      <Modal
        isOpen={isTaskDetailsOpen}
        onClose={() => {
          setIsTaskDetailsOpen(false);
        }}
      >
        <div className="flex items-center justify-between pl-6 pr-2">
          <div className="flex-1 pt-5 truncate">
            <h3
              className="text-sm font-medium text-gray-900 truncate"
              title={taskDetails.name}
            >
              {taskDetails.name}
            </h3>

            <p className="mt-1 text-sm text-gray-500 truncate">
              <span className="mr-2">
                {taskDetails.time} horas{' '}
                {project.status === 'En cotización' && (
                  <span>- S/ {taskDetails.time * project.costPerHour}</span>
                )}
              </span>
              <span
                className={classNames(
                  taskDetails.difficulty === 'Difícil'
                    ? 'text-red-800 bg-red-100'
                    : taskDetails.difficulty === 'Intermedio'
                    ? 'text-yellow-800 bg-yellow-100'
                    : 'text-green-800 bg-green-100',
                  'flex-shrink-0 inline-block px-2 py-0.5 text-xs font-medium rounded-full'
                )}
              >
                {taskDetails.difficulty}
              </span>
            </p>
          </div>
        </div>
        <div className="px-6 pb-5 mt-3 text-sm prose text-gray-500">
          <MDEditor.Markdown source={taskDetails.description} />
        </div>
      </Modal>

      {/* Header */}
      <div className="px-4 py-4 bg-white shadow sm:pb-0 sm:px-6 lg:px-8">
        <div className="md:flex md:items-center md:justify-between">
          {/* Project Details */}
          <div className="flex-1 min-w-0">
            <h1 className="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate">
              {project.name}
            </h1>
            <div className="flex flex-col mt-1 sm:flex-row sm:flex-wrap sm:mt-0 sm:space-x-8">
              <div className="flex items-center mt-2 text-sm text-gray-500">
                <BriefcaseIcon
                  className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
                Part-time
              </div>
              <div className="flex items-center mt-2 text-sm text-gray-500">
                <LocationMarkerIcon
                  className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
                Remoto
              </div>

              <div className="flex items-center mt-2 text-sm text-gray-500">
                {project.status === 'En cotización' ? (
                  <>
                    <CashIcon
                      className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                      aria-hidden="true"
                    />
                    S/ {project.totalCost}
                  </>
                ) : (
                  <>
                    <ClipboardCheckIcon
                      className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                      aria-hidden="true"
                    />
                    {project?.percentage ? project.percentage.toFixed(2) : 0} %
                  </>
                )}
              </div>
              <div className="flex items-center mt-2 text-sm text-gray-500">
                <CalendarIcon
                  className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
                {formatDate(project.startDate)} -{' '}
                {formatDate(project.estimatedDate || project.endDate)}
              </div>
            </div>
          </div>

          {/* Project Options */}
          <div className="mt-5 sm:flex xl:mt-0 xl:ml-4">
            <Listbox value={project.status} onChange={updateProjectStatus}>
              {({ open }) => (
                <>
                  <Listbox.Label className="sr-only">
                    Change published status
                  </Listbox.Label>
                  <div className="relative mb-2 sm:mb-0 sm:mr-2">
                    <div className="inline-flex w-full divide-x rounded-md shadow-sm sm:w-auto divide-primary-700">
                      <div className="relative z-0 inline-flex w-full divide-x rounded-md shadow-sm sm:w-auto divide-primary-700">
                        <div className="relative inline-flex items-center justify-center w-full py-2 pl-3 pr-4 text-white border border-transparent shadow-sm sm:w-auto bg-primary-600 rounded-l-md">
                          <CheckIcon className="w-5 h-5" aria-hidden="true" />
                          <p className="ml-2.5 text-sm font-medium">
                            {project.status}
                          </p>
                        </div>
                        <Listbox.Button className="relative inline-flex items-center p-2 text-sm font-medium text-white bg-primary-600 hover:bg-primary-700 focus:outline-none focus:z-10 focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-50 focus:ring-primary-600 rounded-r-md">
                          <span className="sr-only">
                            Change published status
                          </span>
                          <ChevronDownIcon
                            className="w-5 h-5 text-white"
                            aria-hidden="true"
                          />
                        </Listbox.Button>
                      </div>
                    </div>

                    <Transition
                      show={open}
                      as={Fragment}
                      enter="transition ease-out duration-200"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-150"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Listbox.Options
                        static
                        className="absolute right-0 w-48 mt-2 -ml-1 overflow-hidden origin-top-right bg-white divide-y divide-gray-200 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:left-auto sm:right-0"
                      >
                        {PROJECT_STATUS.map((option) => (
                          <Listbox.Option
                            key={option}
                            className={({ active }) =>
                              classNames(
                                active
                                  ? 'text-white bg-primary-600'
                                  : 'text-gray-900 bg-white',
                                'cursor-pointer select-none relative px-4 py-2 sm:py-3 text-sm'
                              )
                            }
                            value={option}
                          >
                            {({ selected, active }) => (
                              <div className="flex flex-col">
                                <div className="flex justify-between">
                                  <p
                                    className={
                                      selected ? 'font-semibold' : 'font-normal'
                                    }
                                  >
                                    {option}
                                  </p>
                                  {selected ? (
                                    <span
                                      className={
                                        active
                                          ? 'text-white'
                                          : 'text-primary-500'
                                      }
                                    >
                                      <CheckIcon
                                        className="w-5 h-5"
                                        aria-hidden="true"
                                      />
                                    </span>
                                  ) : null}
                                </div>
                              </div>
                            )}
                          </Listbox.Option>
                        ))}
                      </Listbox.Options>
                    </Transition>
                  </div>
                </>
              )}
            </Listbox>

            <span>
              <button
                type="button"
                className="inline-flex items-center justify-center w-full px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm sm:w-auto hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-50 focus:ring-primary-500"
                onClick={() => {
                  setIsProjectEditOpen(true);
                }}
              >
                <PencilIcon
                  className="w-5 h-5 mr-2 -ml-1 text-gray-400"
                  aria-hidden="true"
                />
                Editar
              </button>
            </span>

            {project.status === 'En cotización' && (
              <div className="flex flex-col mt-2 sm:justify-end sm:hidden">
                <button
                  className="justify-center px-4 py-2 text-sm font-medium text-white border border-transparent rounded-md shadow-sm bg-primary-600 hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
                  onClick={() => setChosenSection('new')}
                >
                  <span>Agregar sección</span>
                </button>
              </div>
            )}
          </div>
        </div>

        {/* Section picker */}
        <div className="mt-6">
          {/* Dropdown menu on small screens */}
          <div className="sm:hidden">
            <label htmlFor="selected-tab" className="sr-only">
              Escoger sección
            </label>
            <select
              id="selected-tab"
              name="selected-tab"
              className="block w-full py-2 pl-3 pr-10 text-base border-gray-300 rounded-md focus:outline-none focus:ring-primary-500 focus:border-primary-500 sm:text-sm"
              value={chosenSection}
              onChange={(event) => {
                setChosenSection(event.target.value);
              }}
            >
              <option value="summary">Resumen</option>
              {sections.map((section) => (
                <option key={section._id} value={section._id}>
                  {section.name}
                </option>
              ))}
            </select>
          </div>
          {/* Tabs at small breakpoint and up */}
          <div className="hidden sm:block">
            <nav className="flex -mb-px space-x-8">
              <TabItem
                content="Resumen"
                active={chosenSection === 'summary'}
                onClick={() => {
                  setChosenSection('summary');
                }}
              ></TabItem>
              {sections.map((section) => (
                <TabItem
                  key={section._id}
                  content={section.name}
                  active={chosenSection === section._id}
                  onClick={() => {
                    setChosenSection(section._id);
                  }}
                ></TabItem>
              ))}
              {project.status === 'En cotización' && (
                <TabItem
                  content="Agregar sección"
                  active={chosenSection === 'new'}
                  onClick={() => {
                    setChosenSection('new');
                  }}
                ></TabItem>
              )}
            </nav>
          </div>
        </div>
      </div>
      {/* Content */}
      <div className="px-0 mx-auto max-w-7xl sm:px-6 lg:px-8">
        <Transition show={chosenSection === 'summary'}>
          <div className="pt-6 pb-5 sm:px-5">
            <div className="p-5 bg-white shadow sm:rounded-lg">
              {loading ? (
                <LoadingSpinner />
              ) : (
                <>
                  <div className="flex items-center">
                    <InformationCircleIcon
                      className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                      aria-hidden="true"
                    />
                    <p className="text-gray-700">{project.description}</p>
                  </div>
                  <div className="flex flex-col mt-1 sm:flex-row sm:flex-wrap sm:mt-0 sm:space-x-8">
                    <div className="flex items-center mt-2 text-sm text-gray-500">
                      {project.status === 'En cotización' ? (
                        <>
                          <CashIcon
                            className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                            aria-hidden="true"
                          />
                          S/ {project.totalCost}
                        </>
                      ) : (
                        <>
                          <ClipboardCheckIcon
                            className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                            aria-hidden="true"
                          />
                          {project?.percentage
                            ? project.percentage.toFixed(2)
                            : 0}{' '}
                          %
                        </>
                      )}
                    </div>
                    <div className="flex items-center mt-2 text-sm text-gray-500">
                      <ClockIcon
                        className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                        aria-hidden="true"
                      />
                      {calcSectionsHours(project.sections)} horas (
                      {(
                        calcSectionsHours(project.sections) /
                        project.hoursPerDay /
                        5
                      ).toFixed(1)}{' '}
                      semanas)
                    </div>
                  </div>
                </>
              )}
            </div>
            <ul className="grid grid-cols-1 mt-6 sm:gap-x-6 gap-y-3 sm:grid-cols-2 lg:grid-cols-3">
              {project.status === 'En cotización' && (
                <li>
                  <button
                    className="flex flex-col items-center justify-center w-full h-full py-6 overflow-hidden transition-all bg-white shadow cursor-pointer sm:rounded-lg group ring-offset-2 ring-primary-500 focus:outline-none hover:ring-2 focus:ring-2"
                    onClick={() => setChosenSection('new')}
                  >
                    <PlusCircleIcon
                      className="w-10 h-10 mx-auto text-gray-400 transition-colors group-hover:text-primary-500"
                      aria-hidden="true"
                    />
                    <p>Agregar sección</p>
                  </button>
                </li>
              )}

              {sections.map((section) => (
                <SectionItem
                  key={section._id}
                  section={section}
                  costPerHour={project.costPerHour}
                  hoursPerDay={project.hoursPerDay}
                  projectStatus={project.status}
                  onTap={() => {
                    setChosenSection(section._id);
                  }}
                />
              ))}
            </ul>
          </div>
        </Transition>
        {sections.map((section) => (
          <Transition key={section._id} show={chosenSection === section._id}>
            <SectionPage
              section={section}
              costPerHour={project.costPerHour}
              hoursPerDay={project.hoursPerDay}
              projectStatus={project.status}
              onAddTaskClick={() => {
                setIsTaskCreateOpen(true);
              }}
            >
              {section.tasks.map((task) => (
                <TaskItem
                  key={task._id}
                  task={task}
                  costPerHour={project.costPerHour}
                  projectStatus={project.status}
                  onPercentageChange={(value) => {
                    updateTaskProgress(task._id, null, value);
                  }}
                  onStatusChange={(value) => {
                    updateTaskProgress(task._id, value, null);
                  }}
                  onDetailsClick={() => {
                    setTaskDetails(task);
                    setIsTaskDetailsOpen(true);
                  }}
                  onEditClick={() => {
                    setTaskDetails(task);
                    setIsTaskEditOpen(true);
                  }}
                  onDeleteClick={() => {
                    setSelectedTaskId(task._id);
                    setIsConfirmDeleteOpen(true);
                  }}
                />
              ))}
            </SectionPage>
          </Transition>
        ))}
        <Transition show={chosenSection === 'new'}>
          <div className="px-5 pt-6 pb-5">
            <div className="p-5 space-y-3 bg-white rounded-lg shadow">
              <p className="text-gray-700">
                Complete el formulario para crear una sección
              </p>
              <div className="space-y-4 sm:space-y-0 sm:space-x-4 sm:flex">
                <div>
                  <label
                    htmlFor="inp_name"
                    className="block text-sm font-medium text-gray-900"
                  >
                    Nombre
                  </label>
                  <div className="mt-1">
                    <input
                      type="text"
                      id="inp_name"
                      name="name"
                      className="block w-full rounded-md shadow-sm inp-border-primary sm:text-sm"
                      placeholder="Nombre"
                      value={sectionName}
                      onChange={(event) => setSectionName(event.target.value)}
                    ></input>
                  </div>
                </div>
                <div className="flex-1">
                  <label
                    htmlFor="inp_description"
                    className="block text-sm font-medium text-gray-900"
                  >
                    Descripción
                  </label>
                  <div className="mt-1">
                    <input
                      type="text"
                      id="inp_description"
                      name="description"
                      className="block w-full rounded-md shadow-sm inp-border-primary sm:text-sm"
                      placeholder="Descripción"
                      value={sectionDescription}
                      onChange={(event) =>
                        setSectionDescription(event.target.value)
                      }
                    ></input>
                  </div>
                </div>
                <div className="flex flex-col sm:justify-end">
                  <button
                    className="justify-center px-4 py-2 text-sm font-medium text-white border border-transparent rounded-md shadow-sm bg-primary-600 hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
                    onClick={createSection}
                  >
                    {createSectionLoading ? (
                      <LoadingSpinner />
                    ) : (
                      <span>Crear sección</span>
                    )}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Transition>
      </div>
    </div>
  );
}
