import { useEffect, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { constants } from '../../utils/constants';
import InputErrorMessage from '../InputErrorMessage';
import LoadingSpinner from '../LoadingSpinner';

export default function ProjectEditForm({
  project,
  toggleFocus,
  onSubmit,
  onCancelClick,
}) {
  const {
    register,
    handleSubmit,
    trigger,
    reset,
    setValue,
    setError,
    clearErrors,

    formState: { isSubmitting, isSubmitSuccessful, errors },
  } = useForm();
  const initialFocusRef = useRef();
  const { ref, ...rest } = register('name');

  async function submit(data) {
    let response = await onSubmit(data);
    if (!response.success) {
      setError('server', response.message);
      setTimeout(() => {
        clearErrors('server');
      }, 200);
    }
  }

  useEffect(() => {
    if (isSubmitSuccessful) reset();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSubmitSuccessful]);

  useEffect(() => {
    if (toggleFocus) {
      setTimeout(() => {
        console.log({ current: initialFocusRef.current });
        initialFocusRef.current.focus();
      }, 200);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [toggleFocus]);

  useEffect(() => {
    setValue('name', project.name);
    setValue('description', project.description);
    setValue('costPerHour', project.costPerHour);
    setValue(
      'startDate',
      new Date(project.startDate || null).toISOString().substr(0, 10)
    );
    setValue(
      'endDate',
      new Date(project.endDate || null).toISOString().substr(0, 10)
    );
    setTimeout(() => {
      trigger();
    }, 200);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [project]);

  return (
    <form
      onSubmit={handleSubmit(submit)}
      onKeyDown={(e) => {
        if (e.key === 'Enter') return e.preventDefault();
      }}
      className="flex flex-col h-full bg-white divide-y divide-gray-200 shadow-xl"
    >
      <div className="flex-1 h-0 overflow-y-auto">
        <div className="px-4 py-6 bg-primary-700 sm:px-6">
          <div className="flex items-center justify-between">
            <h2
              id="slide-over-heading"
              className="text-lg font-medium text-white"
            >
              Editar Proyecto
            </h2>
            <div className="flex items-center ml-3 h-7">
              <button
                type="button"
                onClick={onCancelClick}
                className="rounded-md bg-primary-700 text-primary-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
              >
                <span className="sr-only">Close panel</span>
                {/* Heroicon name: x */}
                <svg
                  className="w-6 h-6"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>
          </div>
          <div className="mt-1">
            <p className="text-sm text-primary-300">
              Modifique los datos del formulario para editar el proyecto.
            </p>
          </div>
        </div>
        <div className="flex flex-col justify-between flex-1">
          <div className="px-4 divide-y divide-gray-200 sm:px-6">
            <div className="pt-6 pb-5 space-y-3">
              <div>
                <label
                  htmlFor="inp_edit_name"
                  className="block text-sm font-medium text-gray-900"
                >
                  Nombre
                </label>
                <div className="mt-1">
                  <input
                    type="text"
                    id="inp_edit_name"
                    name="name"
                    {...rest}
                    ref={(e) => {
                      if (e != null) {
                        ref(e);
                        initialFocusRef.current = e;
                      }
                    }}
                    className={`${
                      errors.name ? 'inp-border-red' : 'inp-border-primary'
                    } block w-full shadow-sm sm:text-sm rounded-md`}
                    placeholder="Nombre"
                  ></input>
                </div>
                <InputErrorMessage error={errors.name}></InputErrorMessage>
              </div>
              <div>
                <label
                  htmlFor="inp_edit_description"
                  className="block text-sm font-medium text-gray-900"
                >
                  Descripción
                </label>
                <div className="mt-1">
                  <input
                    type="text"
                    id="inp_edit_description"
                    {...register('description', {
                      required: constants.ERROR_MESSAGES.REQUIRED,
                    })}
                    className={`${
                      errors.description
                        ? 'inp-border-red'
                        : 'inp-border-primary'
                    } block w-full shadow-sm sm:text-sm rounded-md`}
                    placeholder="Descripción"
                  ></input>
                </div>
                <InputErrorMessage
                  error={errors.description}
                ></InputErrorMessage>
              </div>
              <div>
                <label
                  htmlFor="inp_edit_cost_per_hour"
                  className="block text-sm font-medium text-gray-900"
                >
                  Costo por hora
                </label>
                <div className="mt-1">
                  <input
                    type="number"
                    id="inp_edit_cost_per_hour"
                    {...register('costPerHour', {
                      required: constants.ERROR_MESSAGES.REQUIRED,
                    })}
                    className={`${
                      errors.costPerHour
                        ? 'inp-border-red'
                        : 'inp-border-primary'
                    } block w-full shadow-sm sm:text-sm rounded-md`}
                    placeholder="Costo por hora"
                  ></input>
                </div>
                <InputErrorMessage
                  error={errors.costPerHour}
                ></InputErrorMessage>
              </div>
              <div>
                <label
                  htmlFor="inp_edit_start_date"
                  className="block text-sm font-medium text-gray-900"
                >
                  Fecha de Inicio
                </label>
                <div className="mt-1">
                  <input
                    type="date"
                    id="inp_edit_start_date"
                    {...register('startDate', {
                      required: constants.ERROR_MESSAGES.REQUIRED,
                    })}
                    defaultValue={new Date().toISOString().substr(0, 10)}
                    className={`${
                      errors.startDate ? 'inp-border-red' : 'inp-border-primary'
                    } block w-full shadow-sm sm:text-sm rounded-md`}
                    placeholder="Fecha de Inicio"
                  ></input>
                </div>
                <InputErrorMessage error={errors.startDate}></InputErrorMessage>
              </div>
              <div>
                <label
                  htmlFor="inp_edit_end_date"
                  className="block text-sm font-medium text-gray-900"
                >
                  Fecha de Fin
                </label>
                <div className="mt-1">
                  <input
                    type="date"
                    id="inp_edit_end_date"
                    {...register('endDate', {
                      required: constants.ERROR_MESSAGES.REQUIRED,
                    })}
                    className={`${
                      errors.endDate ? 'inp-border-red' : 'inp-border-primary'
                    } block w-full shadow-sm sm:text-sm rounded-md`}
                    placeholder="Fecha de Fin"
                  ></input>
                </div>
                <InputErrorMessage error={errors.endDate}></InputErrorMessage>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex justify-end flex-shrink-0 px-4 py-4">
        <button
          type="button"
          onClick={onCancelClick}
          className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
        >
          Cancelar
        </button>
        <button
          type="submit"
          className="inline-flex justify-center px-4 py-2 ml-4 text-sm font-medium text-white border border-transparent rounded-md shadow-sm bg-primary-600 hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
        >
          {isSubmitting ? (
            <LoadingSpinner></LoadingSpinner>
          ) : (
            <span>Guardar cambios</span>
          )}
        </button>
      </div>
    </form>
  );
}
