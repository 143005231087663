import { Transition } from '@headlessui/react';
import { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import highlightText from '@brianmcallister/highlight-text';
import { useAuth } from '../../contexts/Auth';

import GeneralApi from '../../utils/generalApi';
import PaginationApi from '../../utils/paginationApi';
import { classNames, formatDate } from '../../utils/parseUtils';
import StorageService from '../../utils/storage';

import { showErrorToast, showSuccessToast } from '../../components/Toast';
import LoadingSpinner from '../../components/LoadingSpinner';
import SlideOver from '../../components/SlideOver';
import TabItem from '../../components/TabItem';
import ConfirmModalContent from '../../components/ConfirmModalContent';

import ProjectCreateForm from '../../components/Project/ProjectCreateForm';
import ProjectDetails from '../../components/Project/ProjectDetails';
import ProjectEditForm from '../../components/Project/ProjectEditForm';
import Pagination from '../../components/Pagination';
import Modal from '../../components/Modal';
import {
  CalendarIcon,
  CashIcon,
  ChevronRightIcon,
  ClipboardCheckIcon,
  PlusCircleIcon,
} from '@heroicons/react/solid';

export default function ProjectList() {
  let history = useHistory();
  let auth = useAuth();

  const position = StorageService.get('userData').position;

  // Project List
  const [loading, setLoading] = useState(true);
  const [projects, setProjects] = useState([]);

  const [totals, setTotals] = useState([]);

  // Filters
  const [statusFilter, setStatusFilter] = useState('all');
  const [searchFilter, setSearchFilter] = useState('');

  // Pagination
  const [page, setPage] = useState(1);
  const [maxPage, setMaxPage] = useState(1);
  const [total, setTotal] = useState(0);

  const amountPerPage = 9;

  const generalApi = new GeneralApi(auth, history);
  const projectsApi = new PaginationApi(
    '/projects/list',
    amountPerPage,
    auth,
    history
  );

  // Slide-overs
  // Create Project
  const [isProjectCreateOpen, setIsProjectCreateOpen] = useState(false);

  // Edit Project
  const [isProjectEditOpen, setIsProjectEditOpen] = useState(false);
  const [selectedProjectId, setSelectedProjectId] = useState('');

  // Project Details
  const [isProjectDetailsOpen, setIsProjectDetailsOpen] = useState(false);
  const [detailsProject, setDetailsProject] = useState({});

  // Confirm Modals
  // Delete Project
  const [isConfirmDeleteOpen, setIsConfirmDeleteOpen] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);

  function setEditProjectData(project) {
    setSelectedProjectId(project._id);
    setDetailsProject(project);
  }

  function setDetailsProjectData(project) {
    setSelectedProjectId(project._id);
    setDetailsProject(project);
    setIsProjectDetailsOpen(true);
  }

  async function createProject(data) {
    const result = await generalApi.post(`/projects`, data);
    if (!result.success) {
      showErrorToast(result.message);
      return result;
    }
    getProjects();
    showSuccessToast('Proyecto creado');
    setIsProjectCreateOpen(false);
    return result;
  }

  async function updateProject(data) {
    const result = await generalApi.put(`/projects`, {
      ...data,
      projectId: selectedProjectId,
    });
    if (!result.success) {
      showErrorToast(result.message);
      return result;
    }
    showSuccessToast('Proyecto modificado');
    setIsProjectEditOpen(false);
    setSelectedProjectId('');
    getProjects();
    return result;
  }

  async function deleteProject(projectId) {
    setLoadingDelete(true);
    const result = await generalApi.post(`/projects/delete`, {
      projectId,
    });
    if (!result.success) {
      showErrorToast(result.message);
      setLoadingDelete(false);
      return result;
    }
    showSuccessToast('Proyecto eliminado');
    setIsConfirmDeleteOpen(false);
    setIsProjectDetailsOpen(false);
    setLoadingDelete(false);
    setSelectedProjectId('');
    getProjects();
    return result;
  }

  useEffect(() => {
    getProjects();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statusFilter, searchFilter, page]);

  useEffect(() => {
    getProjectsTotals();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projects]);

  async function getProjects() {
    setLoading(true);
    let result = await projectsApi.getContent(page, {
      search: searchFilter,
      status: statusFilter,
    });
    if (!result.success) {
      setLoading(false);
      return showErrorToast(result.message);
    }
    setProjects(result.data.projects);
    setMaxPage(result.data.pagination.maxPage);
    setTotal(result.data.pagination.total);
    setLoading(false);
  }

  async function getProjectsTotals() {
    setLoading(true);
    let result = await generalApi.get('/projects/totals');
    if (!result.success) {
      setLoading(false);
      return showErrorToast(result.message);
    }
    console.log(result.data.totals);
    setTotals(result.data.totals);
    setLoading(false);
  }

  return (
    <div>
      {/* Slide-over Create */}
      <SlideOver
        isOpen={isProjectCreateOpen}
        onClose={() => {
          setIsProjectCreateOpen(false);
        }}
      >
        <ProjectCreateForm
          toggleFocus={isProjectCreateOpen}
          onSubmit={createProject}
          onCancelClick={() => {
            setIsProjectCreateOpen(false);
          }}
        ></ProjectCreateForm>
      </SlideOver>

      {/* Slide-over Edit */}
      <SlideOver
        isOpen={isProjectEditOpen}
        onClose={() => {
          setIsProjectEditOpen(false);
        }}
      >
        <ProjectEditForm
          project={detailsProject}
          toggleFocus={isProjectEditOpen}
          onSubmit={updateProject}
          onCancelClick={() => {
            setIsProjectEditOpen(false);
          }}
        />
      </SlideOver>

      {/* Slide-over Details */}
      <SlideOver
        isOpen={isProjectDetailsOpen}
        onClose={() => {
          setIsProjectDetailsOpen(false);
        }}
      >
        <ProjectDetails
          project={detailsProject}
          toggleFocus={isProjectDetailsOpen}
          onCloseClick={() => {
            setIsProjectDetailsOpen(false);
            setSelectedProjectId('');
          }}
          onEditClick={(project) => {
            setIsProjectDetailsOpen(false);
            setEditProjectData(project);
            setIsProjectEditOpen(true);
          }}
          onDeleteClick={() => {
            setIsConfirmDeleteOpen(true);
          }}
        ></ProjectDetails>
      </SlideOver>

      <Modal
        isOpen={isConfirmDeleteOpen}
        onClose={() => {
          setIsConfirmDeleteOpen(false);
        }}
      >
        <ConfirmModalContent
          onClose={() => {
            setIsConfirmDeleteOpen(false);
          }}
          onConfirm={() => {
            deleteProject(selectedProjectId);
          }}
          title="Eliminar proyecto"
          content="¿Está seguro que desea eliminar este proyecto?"
          buttonLoading={loadingDelete}
        />
      </Modal>

      {/* Header */}
      <div className="px-4 py-4 bg-white shadow sm:pb-0 sm:px-6 lg:px-8">
        <div className="md:flex md:items-center md:justify-between">
          <h1 className="text-2xl font-bold leading-6 text-gray-900 sm:truncate">
            Proyectos
          </h1>
          <div className="flex mt-3 right-4 sm:right-6 md:mt-0 md:top-6 lg:right-6">
            <button
              onClick={() => {
                setSelectedProjectId('');
                setIsProjectCreateOpen(true);
              }}
              type="button"
              className="inline-flex items-center px-4 py-2 text-sm font-medium text-white border border-transparent rounded-md shadow-sm order-0 bg-primary-600 hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:order-1 sm:ml-3"
            >
              Crear proyecto
            </button>
          </div>
        </div>
        {/* Project Position filter */}
        <div className="mt-4">
          {/* Dropdown menu on small screens */}
          <div className="sm:hidden">
            <label htmlFor="selected-tab" className="sr-only">
              Escoger tipo de proyecto
            </label>
            <select
              id="selected-tab"
              name="selected-tab"
              className="block w-full py-2 pl-3 pr-10 text-base border-gray-300 rounded-md focus:outline-none focus:ring-primary-500 focus:border-primary-500 sm:text-sm"
              value={statusFilter}
              onChange={(event) => {
                setStatusFilter(event.target.value);
              }}
            >
              <option value="all">Todos</option>
              <option value="En cotización">En cotización</option>
              <option value="En progreso">En Progreso</option>
              <option value="Completados">Completados</option>
              <option value="Pagados">Pagados</option>
              <option value="En pausa">En Pausa</option>
            </select>
          </div>
          {/* Tabs at small breakpoint and up */}
          <div className="hidden sm:block">
            <nav className="flex -mb-px space-x-8">
              <TabItem
                content={`Todos (${totals.length > 0 ? totals[0] : '0'})`}
                active={statusFilter === 'all'}
                onClick={() => {
                  setStatusFilter('all');
                }}
              ></TabItem>
              <TabItem
                content={`En cotización (${
                  totals.length > 1 ? totals[1] : '0'
                })`}
                active={statusFilter === 'En cotización'}
                onClick={() => {
                  setStatusFilter('En cotización');
                }}
              ></TabItem>
              <TabItem
                content={`En Progreso (${totals.length > 2 ? totals[2] : '0'})`}
                active={statusFilter === 'En progreso'}
                onClick={() => {
                  setStatusFilter('En progreso');
                }}
              ></TabItem>
              <TabItem
                content={`Completados (${totals.length > 3 ? totals[3] : '0'})`}
                active={statusFilter === 'Completados'}
                onClick={() => {
                  setStatusFilter('Completados');
                }}
              ></TabItem>
              <TabItem
                content={`Pagados (${totals.length > 4 ? totals[4] : '0'})`}
                active={statusFilter === 'Pagados'}
                onClick={() => {
                  setStatusFilter('Pagados');
                }}
              ></TabItem>
              <TabItem
                content={`En Pausa (${totals.length > 5 ? totals[5] : '0'})`}
                active={statusFilter === 'En pausa'}
                onClick={() => {
                  setStatusFilter('En pausa');
                }}
              ></TabItem>
            </nav>
          </div>
        </div>
      </div>

      {/* Projects grid */}
      <div className="px-0 mx-auto max-w-7xl sm:px-6 lg:px-8">
        <div className="flex flex-col mt-4">
          <div className="min-w-full align-middle">
            <div className="flex items-center justify-between px-4 py-3 mb-3 bg-white border-t border-gray-200 shadow sm:rounded-lg sm:px-6">
              <div className="w-full max-w-lg lg:max-w-xs">
                <label htmlFor="inp_search" className="sr-only">
                  Buscar
                </label>
                <div className="relative">
                  <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                    {/* Heroicon name: search */}
                    <svg
                      className="w-5 h-5 text-gray-400"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        fillRule="evenodd"
                        d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </div>
                  <input
                    id="inp_search"
                    name="search"
                    className="block w-full py-2 pl-10 pr-3 leading-5 placeholder-gray-500 bg-white border border-gray-300 rounded-md focus:outline-none focus:placeholder-gray-400 focus:ring-1 focus:ring-primary-500 focus:border-primary-500 sm:text-sm"
                    placeholder="Buscar"
                    type="search"
                    value={searchFilter}
                    onChange={(e) => {
                      setSearchFilter(e.target.value);
                    }}
                  ></input>
                </div>
              </div>
            </div>
            <Transition
              show={loading}
              className="flex items-center p-4 bg-white shadow sm:rounded-lg"
            >
              <LoadingSpinner color="primary"></LoadingSpinner>
            </Transition>
            <Transition show={!loading}>
              <ul className="grid grid-cols-1 mt-3 sm:gap-x-6 gap-y-3 sm:grid-cols-2 lg:grid-cols-3">
                <li>
                  <button
                    className="flex flex-col items-center justify-center w-full h-full py-6 overflow-hidden transition-all bg-white shadow cursor-pointer sm:rounded-lg group ring-offset-2 ring-primary-500 focus:outline-none hover:ring-2 focus:ring-2"
                    onClick={() => setIsProjectCreateOpen(true)}
                  >
                    <PlusCircleIcon
                      className="w-10 h-10 mx-auto text-gray-400 transition-colors group-hover:text-primary-500"
                      aria-hidden="true"
                    />
                    <p>Crear proyecto</p>
                  </button>
                </li>
                {projects.map((project) => (
                  <li key={project._id}>
                    <Link
                      to={`/projects/${project._id}/details`}
                      /*
                      onClick={() => {
                        setDetailsProjectData(project);
                        setIsProjectDetailsOpen(true);
                      }}
                      */
                      className="flex items-center justify-between px-4 py-4 transition-all bg-white shadow sm:rounded-lg hover:bg-gray-50 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-primary-500 focus:outline-none"
                    >
                      <span className="flex flex-1 space-x-2 truncate">
                        <span className="flex flex-col space-y-2 text-sm text-gray-500 truncate">
                          <div className="flex justify-between">
                            <span
                              className="truncate"
                              dangerouslySetInnerHTML={{
                                __html: highlightText(project.name || '', [
                                  searchFilter,
                                ]),
                              }}
                            ></span>

                            <span
                              className={classNames(
                                project.status === 'En progreso'
                                  ? 'bg-green-100 text-green-800'
                                  : 'bg-red-100 text-red-800',
                                'inline-flex items-center text-center px-2.5 py-0.5 ml-2 rounded-full text-xs font-medium capitalize'
                              )}
                            >
                              {project.status}
                            </span>
                          </div>
                          <div className="flex items-center text-sm text-gray-500">
                            {project.status === 'En cotización' ? (
                              <>
                                <CashIcon
                                  className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                                  aria-hidden="true"
                                />
                                S/ {project.totalCost.toFixed(2)}
                              </>
                            ) : (
                              <>
                                <ClipboardCheckIcon
                                  className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                                  aria-hidden="true"
                                />
                                {(project.percentage || 0).toFixed(2)} %
                              </>
                            )}
                          </div>
                          <div className="flex items-center text-sm text-gray-500">
                            <CalendarIcon
                              className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                              aria-hidden="true"
                            />
                            {formatDate(project.startDate)} -{' '}
                            {formatDate(
                              project.estimatedDate || project.endDate
                            )}
                          </div>

                          {position === 'super_admin' ||
                          position === 'admin' ? (
                            <span>
                              {project.createdBy.firstName}{' '}
                              {project.createdBy.lastName}
                            </span>
                          ) : (
                            <></>
                          )}
                        </span>
                      </span>
                      {/* Heroicon name: chevron-right */}
                      <ChevronRightIcon
                        className="flex-shrink-0 w-5 h-5 text-gray-400"
                        aria-hidden="true"
                      />
                    </Link>
                  </li>
                ))}
              </ul>
            </Transition>
            <Transition
              show={!loading && projects.length === 0}
              className="flex items-center p-4 mt-3 bg-white shadow sm:rounded-lg"
            >
              <p>No se encontraron resultados</p>
            </Transition>
            {/* Pagination */}
            <Pagination
              page={page}
              amountPerPage={amountPerPage}
              total={total}
              maxPage={maxPage}
              onPreviousPage={() => {
                setPage(page - 1);
              }}
              onNextPage={() => {
                setPage(page + 1);
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
