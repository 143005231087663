import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { useAuth } from '../../contexts/Auth';

import InputErrorMessage from '../../components/InputErrorMessage';
import LoadingSpinner from '../../components/LoadingSpinner';
import TabItem from '../../components/TabItem';
import { showErrorToast, showSuccessToast } from '../../components/Toast';

import { constants } from '../../utils/constants';
import GeneralApi from '../../utils/generalApi';

export default function UserSettings() {
  let history = useHistory();
  let auth = useAuth();

  // Tab
  const [settingsTab, setSettingsTab] = useState('password');

  const generalApi = new GeneralApi(auth, history);

  const {
    register,
    handleSubmit,
    reset,
    setError,
    clearErrors,

    formState: {
      isSubmitting,
      isSubmitSuccessful,
      errors,
    },
  } = useForm();

  async function submit(data) {
    const result = await generalApi.put(`/users/password`, data);
    if (!result.success) {
      showErrorToast(result.message);
      setError('server', result?.message || '-');
      setTimeout(() => {
        clearErrors('server');
      }, 200);
      return;
    }
    showSuccessToast('Contraseña actualizada');
    return result;
  }

  useEffect(() => {
    if (isSubmitSuccessful) reset();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSubmitSuccessful]);

  return (
    <div>
      {/*<!-- Header -->*/}
      <div className="px-4 py-4 bg-white shadow sm:pb-0 sm:px-6 lg:px-8">
        <div className="md:flex md:items-center md:justify-between">
          <h1 className="text-2xl font-bold leading-6 text-gray-900 sm:truncate py-1.5">
            Configuración
          </h1>
        </div>
        {/* Project Position filter */}
        <div className="mt-4">
          {/* Dropdown menu on small screens */}
          <div className="sm:hidden">
            <label htmlFor="selected-tab" className="sr-only">
              Escoger pestaña
            </label>
            <select
              id="selected-tab"
              name="selected-tab"
              className="block w-full py-2 pl-3 pr-10 text-base border-gray-300 rounded-md focus:outline-none focus:ring-primary-500 focus:border-primary-500 sm:text-sm"
              value={settingsTab}
              onChange={(event) => {
                setSettingsTab(event.target.value);
              }}
            >
              <option value="password">Contraseña</option>
            </select>
          </div>
          {/* Tabs at small breakpoint and up */}
          <div className="hidden sm:block">
            <nav className="flex -mb-px space-x-8">
              <TabItem
                content="Contraseña"
                active={settingsTab === 'password'}
                onClick={() => {
                  setSettingsTab('password');
                }}
              ></TabItem>
            </nav>
          </div>
        </div>
      </div>
      <div className="px-0 mx-auto max-w-7xl sm:px-6 lg:px-8">
        <div className="space-y-6 sm:p-6 lg:px-8 lg:col-span-9">
          <section aria-labelledby="update_password_heading">
            <form
              onSubmit={handleSubmit(submit)}
              onKeyDown={(e) => {
                if (e.key === 'Enter') return e.preventDefault();
              }}
            >
              <div className="shadow sm:rounded-md sm:overflow-hidden">
                <div className="px-4 py-6 bg-white sm:p-6">
                  <div>
                    <h2
                      id="update_password_heading"
                      className="text-lg font-medium leading-6 text-gray-900"
                    >
                      Cambiar Contraseña
                    </h2>
                    <p className="mt-1 text-sm text-gray-500">
                      Ingrese su contraseña actual y la nueva contraseña que
                      desea guardar
                    </p>
                  </div>

                  <div className="grid grid-cols-4 gap-6 mt-6">
                    <div className="col-span-4 sm:col-span-2">
                      <label
                        htmlFor="inp_password_now"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Contraseña actual
                      </label>
                      <input
                        type="password"
                        {...register('passwordNow', {
                          validate: {
                            required: (value) =>
                              value !== null && value !== ''
                                ? null
                                : constants.ERROR_MESSAGES.REQUIRED,
                            minLength: (value) =>
                              value.length >= 6
                                ? null
                                : constants.ERROR_MESSAGES.MIN_LENGTH(6),
                          },
                        })}
                        id="inp_password_now"
                        autoComplete="password"
                        className={`${
                          errors.passwordNow
                            ? 'inp-border-red'
                            : 'inp-border-primary'
                        } mt-1 block w-full shadow-sm sm:text-sm rounded-md`} />
                      <InputErrorMessage
                        error={errors.passwordNow}
                      ></InputErrorMessage>
                    </div>

                    <div className="col-span-4 sm:col-span-2">
                      <label
                        htmlFor="inp_password_new"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Nueva contraseña
                      </label>
                      <input
                        type="password"
                        {...register('passwordNew', {
                          validate: {
                            required: (value) =>
                              value !== null && value !== ''
                                ? null
                                : constants.ERROR_MESSAGES.REQUIRED,
                            minLength: (value) =>
                              value.length >= 6
                                ? null
                                : constants.ERROR_MESSAGES.MIN_LENGTH(6),
                          },
                        })}
                        id="inp_password_new"
                        autoComplete="new-password"
                        className={`${
                          errors.passwordNew
                            ? 'inp-border-red'
                            : 'inp-border-primary'
                        } mt-1 block w-full shadow-sm sm:text-sm rounded-md`} />
                      <InputErrorMessage
                        error={errors.passwordNew}
                      ></InputErrorMessage>
                    </div>
                  </div>
                </div>
                <div className="px-4 py-3 text-right bg-gray-50 sm:px-6">
                  <button
                    type="submit"
                    className="inline-flex justify-center px-4 py-2 text-sm font-medium text-white border border-transparent rounded-md shadow-sm bg-primary-600 hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
                  >
                    {isSubmitting ? (
                      <LoadingSpinner></LoadingSpinner>
                    ) : (
                      <span>Guardar cambios</span>
                    )}
                  </button>
                </div>
              </div>
            </form>
          </section>
        </div>
      </div>
    </div>
  );
}
