import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Transition } from '@headlessui/react';

export default function SidebarItemParent({
  sidebarItem,
  paths,
  children,
  ...rest
}) {
  const location = useLocation();
  const isActive = paths.includes(location.pathname);

  const [isOpen, setIsOpen] = useState(isActive);

  return (
    <div>
      <button
        onClick={() => {
          setIsOpen(!isOpen);
        }}
        className={`${
          isActive
            ? 'bg-gray-100 text-gray-900 border-l-8'
            : 'text-gray-600 hover:bg-gray-50 hover:text-gray-900 hover:border-l-8 focus:bg-gray-50 focus:text-gray-900 focus:border-l-8'
        } group flex items-center px-2 py-2 w-full text-sm font-medium rounded-md border-primary-600 transition-all duration-150 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-primary-500`}
        {...rest}
      >
        {sidebarItem.icon} {sidebarItem.title}
        <svg
          className={`${
            isOpen ? 'text-gray-500 rotate-90' : 'text-gray-400'
          } ml-auto h-5 w-5 transform group-hover:text-gray-400 group-focus:text-gray-400 transition-all ease-in-out duration-150`}
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M19 9l-7 7-7-7"
          />
        </svg>
      </button>

      <Transition show={isOpen} className="pt-1 space-y-2">
        {children}
      </Transition>
    </div>
  );
}
