import { addMinutes, format } from 'date-fns';
import { es } from 'date-fns/esm/locale';

export function formatDate(dateString, formatString = `do 'de' MMMM yyyy`) {
  if (!dateString) return '-';
  const date = new Date(dateString || null);
  return format(addMinutes(date, date.getTimezoneOffset()), formatString, {
    locale: es,
  });
}

export function calcSectionsHours(sections) {
  return sections.reduce(
    (total, section) =>
      (total += section.tasks.reduce(
        (taskTotal, task) => (taskTotal += task.time),
        0
      )),
    0
  );
}
export function calcTasksHours(tasks) {
  return tasks.reduce((taskTotal, task) => (taskTotal += task.time), 0);
}
export function calcTasksPercentage(tasks) {
  return (
    tasks.reduce((taskTotal, task) => (taskTotal += task.percentage), 0) /
    tasks.length
  );
}

export function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}
