import {useState} from 'react';
import {useHistory} from 'react-router-dom';
import LoadingSpinner from '../../components/LoadingSpinner';
import {showErrorToast, showSuccessToast} from '../../components/Toast';
import {useAuth} from '../../contexts/Auth';

import {CheckCircleIcon} from '@heroicons/react/solid';
import logo from '../../resources/img/logo_icon.svg';

export default function Login() {
  let history = useHistory();
  let auth = useAuth();

  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  let login = async () => {
    setLoading(true);
    let result = await auth.signin(email, password);
    if (!result.success) {
      setLoading(false);
      return showErrorToast(result.message);
    }
    showSuccessToast(result.message);
    history.replace(result?.data?.redirect || '/dashboard');
  };

  return (
    <div className="flex min-h-screen bg-white">
      <div className="flex flex-col justify-center flex-1 px-4 py-12 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
        <div className="w-full max-w-sm mx-auto lg:w-96">
          <div>
            <img className="w-auto h-12" src={logo} alt="Workflow"></img>
            <h2 className="mt-6 text-3xl font-extrabold text-gray-900">
              Ingresa a tu cuenta
            </h2>
          </div>

          <div className="rounded-md bg-green-50 p-4 mt-8">
            <div className="flex">
              <div className="flex-shrink-0">
                <CheckCircleIcon
                  className="h-5 w-5 text-green-400"
                  aria-hidden="true"
                />
              </div>
              <div className="ml-3">
                <h3 className="text-sm font-medium text-green-800">
                  Login details
                </h3>
                <div className="mt-2 text-sm text-green-700">
                  <p>Username: test@gmail.com</p>
                  <p>Password: 123456</p>
                </div>
              </div>
            </div>
          </div>

          <div className="mt-4">
            <div className="mt-6">
              <div className="space-y-6">
                <div>
                  <label
                    htmlFor="inp_document_number"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Correo
                  </label>
                  <div className="mt-1">
                    <input
                      id="inp_email"
                      name="email"
                      type="email"
                      value={email}
                      autoFocus
                      onChange={(e) => setEmail(e.target.value)}
                      onKeyUp={(e) => {
                        if (e.key === 'Enter') login();
                      }}
                      className="relative block w-full px-3 py-2 placeholder-gray-400 border border-gray-300 rounded-md shadow-sm appearance-none rounded-b-md focus:outline-none focus:z-10 focus:ring-primary-500 focus:border-primary-500 sm:text-sm"
                    ></input>
                  </div>
                </div>

                <div className="space-y-1">
                  <label
                    htmlFor="inp_password"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Contraseña
                  </label>
                  <div className="mt-1">
                    <input
                      id="inp_password"
                      name="password"
                      type="password"
                      autoComplete="current-password"
                      required
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      onKeyUp={(e) => {
                        if (e.key === 'Enter') login();
                      }}
                      className="block w-full px-3 py-2 placeholder-gray-400 border border-gray-300 rounded-md shadow-sm appearance-none focus:outline-none focus:ring-primary-500 focus:border-primary-500 sm:text-sm"
                    ></input>
                  </div>
                </div>

                <div>
                  <button
                    onClick={login}
                    className="inline-flex items-center justify-center w-full px-4 py-2 text-sm font-medium text-white border border-transparent rounded-md shadow-sm bg-primary-600 hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
                  >
                    {loading ? (
                      <LoadingSpinner></LoadingSpinner>
                    ) : (
                      <span>Ingresar</span>
                    )}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="relative flex-1 hidden w-0 lg:block">
        <img
          className="absolute inset-0 object-cover w-full h-full"
          src="https://www.kabbage.com/resource-center/static/697f67813cf64f75e2adb824ff914ce3/26852/how-to-write-a-business-plan_207251500-1024x688.jpg"
          alt=""
        ></img>
      </div>
    </div>
  );
}
