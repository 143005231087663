import { useEffect, useRef } from 'react';
import { Controller, useForm } from 'react-hook-form';
import MDEditor from '@uiw/react-md-editor';

import { constants } from '../../utils/constants';
import InputErrorMessage from '../InputErrorMessage';
import LoadingSpinner from '../LoadingSpinner';

export default function TaskEditForm({
  task,
  toggleFocus,
  onSubmit,
  onCancelClick,
}) {
  const {
    register,
    handleSubmit,
    trigger,
    reset,
    setValue,
    setError,
    clearErrors,
    control,

    formState: { isSubmitting, isSubmitSuccessful, errors },
  } = useForm();
  const { ref: nameRef, ...nameRest } = register('name', {
    required: constants.ERROR_MESSAGES.REQUIRED,
  })
  const initialFocusRef = useRef();

  async function submit(data) {
    let response = await onSubmit(data);
    if (!response.success) {
      setError('server', response.message);
      setTimeout(() => {
        clearErrors('server');
      }, 200);
    }
  }

  useEffect(() => {
    if (isSubmitSuccessful) reset();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSubmitSuccessful]);

  useEffect(() => {
    if (toggleFocus) {
      setTimeout(() => {
        initialFocusRef.current.focus();
      }, 200);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [toggleFocus]);

  useEffect(() => {
    setValue('name', task.name);
    setValue('description', task.description);
    setValue('time', task.time);
    setValue('difficulty', task.difficulty);
    setTimeout(() => {
      trigger();
    }, 200);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [task]);

  return (
    <form
      onSubmit={handleSubmit(submit)}
      className="flex flex-col h-full bg-white divide-y divide-gray-200 shadow-xl"
    >
      <div className="flex-1 h-0 overflow-y-auto">
        <div className="px-4 py-6 bg-primary-700 sm:px-6">
          <div className="flex items-center justify-between">
            <h2
              id="slide-over-heading"
              className="text-lg font-medium text-white"
            >
              Editar Tarea
            </h2>
            <div className="flex items-center ml-3 h-7">
              <button
                type="button"
                onClick={onCancelClick}
                className="rounded-md bg-primary-700 text-primary-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
              >
                <span className="sr-only">Close panel</span>
                {/* Heroicon name: x */}
                <svg
                  className="w-6 h-6"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>
          </div>
          <div className="mt-1">
            <p className="text-sm text-primary-300">
              Llene los datos del formulario para modificar la tarea.
            </p>
          </div>
        </div>
        <div className="flex flex-col justify-between flex-1">
          <div className="px-4 divide-y divide-gray-200 sm:px-6">
            <div className="pt-6 pb-5 space-y-3">
              <div>
                <label
                  htmlFor="inp_edit_name"
                  className="block text-sm font-medium text-gray-900"
                >
                  Nombre
                </label>
                <div className="mt-1">
                  <input
                    type="text"
                    id="inp_edit_name"
                    {...nameRest}
                    ref={(e) => {
                      if (e != null) {
                        nameRef(e)
                        initialFocusRef.current = e;
                      }
                    }}
                    className={`${
                      errors.name ? 'inp-border-red' : 'inp-border-primary'
                    } block w-full shadow-sm sm:text-sm rounded-md`}
                    placeholder="Nombre"
                  ></input>
                </div>
                <InputErrorMessage error={errors.name}></InputErrorMessage>
              </div>
              <div>
                <label
                  htmlFor="inp_edit_description"
                  className="block text-sm font-medium text-gray-900"
                >
                  Descripción
                </label>
                <div className="mt-1">
                  <Controller
                    control={control}
                    name="description"
                    rules={{ required: constants.ERROR_MESSAGES.REQUIRED }}
                    render={({ field: { onChange, value } }) => (
                      <MDEditor
                        id="inp_description"
                        value={value}
                        onChange={onChange}
                        preview="edit"
                        textareaProps={{
                          placeholder: 'Descripción',
                        }}
                      />
                    )}
                  />
                </div>
                <InputErrorMessage
                  error={errors.description}
                ></InputErrorMessage>
              </div>
              <div>
                <label
                  htmlFor="inp_edit_time"
                  className="block text-sm font-medium text-gray-900"
                >
                  Cantidad de horas
                </label>
                <div className="mt-1">
                  <input
                    type="number"
                    id="inp_edit_time"
                    {...register('time', {
                      required: constants.ERROR_MESSAGES.REQUIRED,
                    })}
                    className={`${
                      errors.time ? 'inp-border-red' : 'inp-border-primary'
                    } block w-full shadow-sm sm:text-sm rounded-md`}
                    placeholder="Cantidad de horas"
                  ></input>
                </div>
                <InputErrorMessage error={errors.time}></InputErrorMessage>
              </div>
              <div>
                <label
                  htmlFor="inp_edit_difficulty"
                  className="block text-sm font-medium text-gray-900"
                >
                  Dificultad
                </label>
                <div className="mt-1">
                  <select
                    id="inp_edit_difficulty"
                    {...register('difficulty')}
                    className={`${
                      errors.difficulty
                        ? 'inp-border-red'
                        : 'inp-border-primary'
                    } block w-full shadow-sm sm:text-sm rounded-md`}
                  >
                    <option value="Fácil">Fácil</option>
                    <option value="Intermedio">Intermedio</option>
                    <option value="Difícil">Difícil</option>
                  </select>
                </div>
                <InputErrorMessage
                  error={errors.difficulty}
                ></InputErrorMessage>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex justify-end flex-shrink-0 px-4 py-4">
        <button
          type="button"
          onClick={onCancelClick}
          className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
        >
          Cancelar
        </button>
        <button
          type="submit"
          className="inline-flex justify-center px-4 py-2 ml-4 text-sm font-medium text-white border border-transparent rounded-md shadow-sm bg-primary-600 hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
          onClick={() => {
            handleSubmit(submit);
          }}
        >
          {isSubmitting ? (
            <LoadingSpinner></LoadingSpinner>
          ) : (
            <span>Guardar cambios</span>
          )}
        </button>
      </div>
    </form>
  );
}
