import './App.css';

import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import PrivateRoute from './components/Utils/ProtectedRoute';
import PublicOnlyRoute from './components/Utils/PublicOnlyRoute';
import { ProvideAuth } from './contexts/Auth';

import Login from './pages/auth/Login';
import UserList from './pages/user/List';
import Settings from './pages/user/Settings';
import Dashboard from './pages/Dashboard';
import NotFound from './pages/misc/NotFound';
import ProjectList from './pages/project/List';
import ProjectDetails from './pages/project/Details';

function App() {
  return (
    <ProvideAuth>
      <Router>
        <Switch>
          <PublicOnlyRoute exact path="/">
            <Login />
          </PublicOnlyRoute>
          <PrivateRoute path="/dashboard">
            <Dashboard />
          </PrivateRoute>
          <PrivateRoute path="/settings">
            <Settings />
          </PrivateRoute>
          <PrivateRoute path="/projects/:projectId/details">
            <ProjectDetails />
          </PrivateRoute>
          <PrivateRoute path="/projects">
            <ProjectList />
          </PrivateRoute>
          <PrivateRoute path="/users">
            <UserList />
          </PrivateRoute>
          <PublicOnlyRoute path="/login">
            <Login />
          </PublicOnlyRoute>
          <Route path="*">
            <NotFound />
          </Route>
        </Switch>
        <div
          id="toast-container"
          className="fixed bottom-0 left-0 right-0 z-50 sm:bottom-auto sm:left-auto sm:top-16 sm:right-4"
        ></div>
      </Router>
    </ProvideAuth>
  );
}

export default App;
