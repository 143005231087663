import { Menu, Transition } from '@headlessui/react';
import { useEffect, useRef } from 'react';
import { constants } from '../../utils/constants';
import LoadingSpinner from '../LoadingSpinner';

export default function UserDetails({
  user,
  toggleFocus,
  toggleLoading,
  onCloseClick,
  onEditClick,
  onToggleClick,
  onDeleteClick,
}) {
  const initialFocusRef = useRef();

  useEffect(() => {
    if (toggleFocus) {
      setTimeout(() => {
        initialFocusRef.current.focus();
      }, 200);
    }
  }, [toggleFocus]);

  return (
    <div className="flex flex-col h-full overflow-y-scroll bg-white shadow-xl">
      <div className="px-4 py-6 sm:px-6">
        <div className="flex items-start justify-between">
          <h2
            id="slide-over-heading"
            className="text-lg font-medium text-gray-900"
          >
            Perfil de Empleado
          </h2>
          <div className="flex items-center ml-3 h-7">
            <button
              onClick={() => {
                onCloseClick();
              }}
              className="text-gray-400 bg-white rounded-md hover:text-gray-500 focus:ring-2 focus:ring-primary-500"
            >
              <span className="sr-only">Close panel</span>
              {/* Heroicon name: x */}
              <svg
                className="w-6 h-6"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          </div>
        </div>
      </div>
      {/* Main */}
      <div>
        <div className="pb-1 sm:pb-6">
          <div>
            <div className="relative h-20 lg:h-28 bg-primary-700"></div>
            <div className="px-4 mt-6 sm:mt-8 sm:flex sm:items-end sm:px-6">
              <div className="sm:flex-1">
                <div>
                  <div className="items-center sm:flex">
                    <h3 className="text-xl font-bold text-gray-900 sm:text-2xl">
                      {user.firstName} {user.lastName}
                    </h3>
                    <span
                      className={`${
                        user.isActive
                          ? 'text-green-800 bg-green-100'
                          : 'text-red-800 bg-red-100'
                      } mt-2.5 sm:mt-0 sm:ml-2.5 inline-block px-2 py-0.5 text-sm font-medium rounded-full`}
                    >
                      {constants.USER_POSITIONS_DISPLAY[user.position]}
                    </span>
                  </div>
                </div>
                <div className="flex flex-wrap mt-5 space-y-3 sm:space-y-0 sm:space-x-3">
                  <button
                    onClick={() => {
                      onEditClick(user);
                    }}
                    ref={initialFocusRef}
                    type="button"
                    className="inline-flex items-center justify-center flex-shrink-0 w-full px-4 py-2 text-sm font-medium text-white border border-transparent rounded-md shadow-sm bg-primary-600 hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:flex-1"
                  >
                    Editar
                  </button>
                  {user.position !== 'super_admin' ? (
                    <button
                      type="button"
                      className="inline-flex items-center justify-center flex-1 w-full px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
                    >
                      Permisos
                    </button>
                  ) : null}
                  {user.position !== 'super_admin' ? (
                    <span className="inline-flex ml-3 sm:ml-0">
                      <div className="relative inline-block text-left">
                        <Menu>
                          {({ open }) => (
                            <>
                              <Menu.Button className="inline-flex items-center p-2 text-sm font-medium text-gray-400 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500">
                                <span className="sr-only">
                                  Open options menu
                                </span>
                                {/* Heroicon name: dots-vertical */}
                                <svg
                                  className="w-5 h-5"
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 20 20"
                                  fill="currentColor"
                                  aria-hidden="true"
                                >
                                  <path d="M10 6a2 2 0 110-4 2 2 0 010 4zM10 12a2 2 0 110-4 2 2 0 010 4zM10 18a2 2 0 110-4 2 2 0 010 4z" />
                                </svg>
                              </Menu.Button>

                              <Transition
                                show={open}
                                enter="transition ease-out duration-100"
                                enterFrom="opacity-0 scale-95"
                                enterTo="opacity-100 scale-100"
                                leave="transition ease-in duration-75"
                                leaveFrom="opacity-100 scale-100"
                                leaveTo="opacity-0 scale-95"
                              >
                                <Menu.Items
                                  static
                                  className="absolute right-0 w-48 mt-2 origin-top-right bg-white rounded-md shadow-lg ring-1 ring-black ring-opacity-5"
                                >
                                  <Menu.Item>
                                    {({ active }) => (
                                      <button
                                        className={`${
                                          active
                                            ? 'bg-gray-100 text-gray-900'
                                            : 'text-gray-700'
                                        } block px-4 py-2 text-sm cursor-pointer w-full text-left`}
                                        onClick={() => {
                                          onToggleClick(user);
                                        }}
                                      >
                                        {toggleLoading ? (
                                          <LoadingSpinner></LoadingSpinner>
                                        ) : (
                                          <span>
                                            {user.isActive
                                              ? 'Deshabilitar'
                                              : 'Habilitar'}
                                          </span>
                                        )}
                                      </button>
                                    )}
                                  </Menu.Item>
                                  <Menu.Item>
                                    {({ active }) => (
                                      <button
                                        className={`${
                                          active
                                            ? 'bg-gray-100 text-gray-900'
                                            : 'text-gray-700'
                                        } block px-4 py-2 text-sm cursor-pointer w-full text-left`}
                                        onClick={onDeleteClick}
                                      >
                                        Eliminar
                                      </button>
                                    )}
                                  </Menu.Item>
                                </Menu.Items>
                              </Transition>
                            </>
                          )}
                        </Menu>
                      </div>
                    </span>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="px-4 pt-5 pb-5 sm:px-0 sm:pt-0">
          <dl className="px-4 space-y-8 sm:px-6 sm:space-y-6">
            <div>
              <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0">
                Correo
              </dt>
              <dd className="mt-1 text-sm text-gray-900 sm:col-span-2">
                {user.email}
              </dd>
            </div>
          </dl>
        </div>
      </div>
    </div>
  );
}
