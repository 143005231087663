import { useEffect, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { constants } from '../../utils/constants';
import InputErrorMessage from '../InputErrorMessage';
import LoadingSpinner from '../LoadingSpinner';

export default function UserEditForm({
  user,
  toggleFocus,
  onSubmit,
  onCancelClick,
}) {
  const {
    register,
    handleSubmit,
    trigger,
    reset,
    setValue,
    setError,
    clearErrors,

    formState: {
      isSubmitting,
      isSubmitSuccessful,
      errors,
    },
  } = useForm();
  const { ref: nameRef, ...nameRest } = register('firstName', {
    required: constants.ERROR_MESSAGES.REQUIRED,
  })
  const initialFocusRef = useRef();

  async function submit(data) {
    let response = await onSubmit(data);
    if (!response.success) {
      setError('server', response.message);
      setTimeout(() => {
        clearErrors('server');
      }, 200);
    }
  }

  useEffect(() => {
    if (isSubmitSuccessful) reset();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSubmitSuccessful]);

  useEffect(() => {
    if (toggleFocus) {
      setTimeout(() => {
        initialFocusRef.current.focus();
      }, 200);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [toggleFocus]);

  useEffect(() => {
    setValue('firstName', user.firstName);
    setValue('lastName', user.lastName);
    setValue('email', user.email);
    setValue('phoneNumber', user.phoneNumber);
    setValue('position', user.position);
    setValue('password', '');
    setTimeout(() => {
      trigger();
    }, 200);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  return (
    <form
      onSubmit={handleSubmit(submit)}
      onKeyDown={(e) => {
        if (e.key === 'Enter') return e.preventDefault();
      }}
      className="flex flex-col h-full bg-white divide-y divide-gray-200 shadow-xl"
    >
      <div className="flex-1 h-0 overflow-y-auto">
        <div className="px-4 py-6 bg-primary-700 sm:px-6">
          <div className="flex items-center justify-between">
            <h2
              id="slide-over-heading"
              className="text-lg font-medium text-white"
            >
              Editar Empleado
            </h2>
            <div className="flex items-center ml-3 h-7">
              <button
                type="button"
                onClick={onCancelClick}
                className="rounded-md bg-primary-700 text-primary-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
              >
                <span className="sr-only">Close panel</span>
                {/* Heroicon name: x */}
                <svg
                  className="w-6 h-6"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>
          </div>
          <div className="mt-1">
            <p className="text-sm text-primary-300">
              Modifique los datos del formulario para editar el empleado.
            </p>
          </div>
        </div>
        <div className="flex flex-col justify-between flex-1">
          <div className="px-4 divide-y divide-gray-200 sm:px-6">
            <div className="pt-6 pb-5 space-y-3">
              <div>
                <label
                  htmlFor="inp_edit_first_name"
                  className="block text-sm font-medium text-gray-900"
                >
                  Nombres
                </label>
                <div className="mt-1">
                  <input
                    type="text"
                    id="inp_edit_first_name"
                    name="firstName"
                    {...nameRest}
                    ref={(e) => {
                      if (e != null) {
                        nameRef(e)
                        initialFocusRef.current = e;
                      }
                    }}
                    className={`${
                      errors.firstName ? 'inp-border-red' : 'inp-border-primary'
                    } block w-full shadow-sm sm:text-sm rounded-md`}
                    placeholder="Nombres"
                  ></input>
                </div>
                <InputErrorMessage error={errors.firstName}></InputErrorMessage>
              </div>
              <div>
                <label
                  htmlFor="inp_edit_last_name_p"
                  className="block text-sm font-medium text-gray-900"
                >
                  Apellidos
                </label>
                <input
                  type="text"
                  id="inp_edit_last_name"
                  {...register('lastName', {
                    required: constants.ERROR_MESSAGES.REQUIRED,
                  })}
                  className={`${
                    errors.lastName ? 'inp-border-red' : 'inp-border-primary'
                  } relative block w-full rounded-none rounded-l-md bg-transparent focus:z-10 sm:text-sm`}
                  placeholder="Paterno"></input>
                <InputErrorMessage error={errors.lastName}></InputErrorMessage>
              </div>
              <div>
                <label
                  htmlFor="inp_edit_email"
                  className="block text-sm font-medium text-gray-900"
                >
                  Correo
                </label>
                <div className="mt-1">
                  <input
                    type="email"
                    id="inp_edit_email"
                    {...register('email', {
                      required: constants.ERROR_MESSAGES.REQUIRED,
                      pattern: {
                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                        message: constants.ERROR_MESSAGES.WRONG_EMAIL,
                      },
                    })}
                    className={`${
                      errors.email ? 'inp-border-red' : 'inp-border-primary'
                    } block w-full shadow-sm sm:text-sm rounded-md`}
                    placeholder="Correo"></input>
                </div>
                <InputErrorMessage error={errors.email}></InputErrorMessage>
              </div>
              <div>
                <label
                  htmlFor="inp_edit_phone_number"
                  className="block text-sm font-medium text-gray-900"
                >
                  Teléfono
                </label>
                <div className="mt-1">
                  <input
                    type="number"
                    id="inp_edit_phone_number"
                    {...register('phone_number')}
                    className="block w-full rounded-md shadow-sm inp-border-primary sm:text-sm"
                    placeholder="Teléfono"></input>
                </div>
              </div>
              <div>
                <label
                  htmlFor="inp_edit_position"
                  className="block text-sm font-medium text-gray-900"
                >
                  Cargo
                </label>
                <div className="mt-1">
                  <select
                    id="inp_edit_position"
                    {...register('position')}
                    className="relative block w-full bg-transparent border-gray-300 rounded-md focus:ring-primary-500 focus:border-primary-500 focus:z-10 sm:text-sm">
                    <option
                      hidden={user.position !== 'super_admin'}
                      value="super_admin"
                    >
                      Administrador
                    </option>
                    <option
                      hidden={user.position === 'super_admin'}
                      value="admin"
                    >
                      Administrador
                    </option>
                    <option
                      hidden={user.position === 'super_admin'}
                      value="projects"
                    >
                      Proyectos
                    </option>
                  </select>
                </div>
              </div>
              <div>
                <label
                  htmlFor="inp_edit_password"
                  className="block text-sm font-medium text-gray-900"
                >
                  Contraseña
                </label>
                <div className="mt-1">
                  <input
                    type="password"
                    id="inp_edit_password"
                    {...register('password')}
                    className={`${
                      errors.password ? 'inp-border-red' : 'inp-border-primary'
                    } block w-full shadow-sm sm:text-sm rounded-md`}
                    placeholder="Contraseña"
                    autoComplete="new-password"></input>
                </div>
                <InputErrorMessage error={errors.password}></InputErrorMessage>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex justify-end flex-shrink-0 px-4 py-4">
        <button
          type="button"
          onClick={onCancelClick}
          className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
        >
          Cancelar
        </button>
        <button
          type="submit"
          className="inline-flex justify-center px-4 py-2 ml-4 text-sm font-medium text-white border border-transparent rounded-md shadow-sm bg-primary-600 hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
        >
          {isSubmitting ? (
            <LoadingSpinner></LoadingSpinner>
          ) : (
            <span>Guardar cambios</span>
          )}
        </button>
      </div>
    </form>
  );
}
