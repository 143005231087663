import {
  ClockIcon,
  CashIcon,
  InformationCircleIcon,
  PlusCircleIcon,
  ClipboardCheckIcon,
} from '@heroicons/react/solid';
import { calcTasksHours, calcTasksPercentage } from '../../../utils/parseUtils';

export default function SectionPage({
  section,
  costPerHour = 30,
  hoursPerDay = 4,
  projectStatus = 'En cotización',
  onAddTaskClick,
  children,
}) {
  return (
    <div className="pt-6 pb-5 sm:px-5">
      <div className="p-5 bg-white shadow sm:rounded-lg">
        <div className="flex items-center">
          <InformationCircleIcon
            className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
            aria-hidden="true"
          />
          <p className="text-gray-700">{section.description}</p>
        </div>
        <div className="flex flex-col mt-1 sm:flex-row sm:flex-wrap sm:mt-0 sm:space-x-8">
          <div className="flex items-center mt-2 text-sm text-gray-500">
            {projectStatus === 'En cotización' ? (
              <>
                <CashIcon
                  className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
                S/ {calcTasksHours(section.tasks) * costPerHour}
              </>
            ) : (
              <>
                <ClipboardCheckIcon
                  className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
                {calcTasksPercentage(section.tasks).toFixed(2) || 0} %
              </>
            )}
          </div>
          <div className="flex items-center mt-2 text-sm text-gray-500">
            <ClockIcon
              className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
              aria-hidden="true"
            />
            {calcTasksHours(section.tasks)} horas (
            {(calcTasksHours(section.tasks) / hoursPerDay / 5).toFixed(1)}{' '}
            semanas)
          </div>
        </div>
      </div>
      <ul className="grid grid-cols-1 mt-6 sm:gap-x-6 gap-y-3 sm:grid-cols-2 lg:grid-cols-3">
        {projectStatus === 'En cotización' && (
          <li>
            <button
              className="flex flex-col items-center justify-center w-full h-full py-6 overflow-hidden transition-all bg-white shadow cursor-pointer sm:rounded-lg group ring-offset-2 ring-primary-500 focus:outline-none hover:ring-2 focus:ring-2"
              onClick={onAddTaskClick}
            >
              <PlusCircleIcon
                className="w-10 h-10 mx-auto text-gray-400 transition-colors group-hover:text-primary-500"
                aria-hidden="true"
              />
              <p>Agregar tarea</p>
            </button>
          </li>
        )}
        {children}
      </ul>
    </div>
  );
}
