const environment = process.env.NODE_ENV;

export const constants = {
  API_URL:
    environment === 'production'
      ? 'https://zegaru-projects.herokuapp.com'
      : 'http://192.168.101.15:3001',

  USER_POSITIONS_FILTER: [
    { value: 'all', content: 'Todos' },
    { value: 'super_admin,admin', content: 'Administradores' },
    { value: 'projects', content: 'Operaciones' },
    //{ value: 'seller', content: 'Vendedores' },
    //{ value: 'inventory', content: 'Inventario' },
    //{ value: 'crm', content: 'CRM' },
    //{ value: 'expenses', content: 'Compras' },
  ],

  USER_POSITIONS_DISPLAY: {
    all: 'Todos',
    super_admin: 'Administrador',
    projects: 'Proyectos',
  },

  ERROR_MESSAGES: {
    REQUIRED: 'Este campo es obligatorio',
    REQUIRED_PLURAL: 'Este campo es obligatorio',
    WRONG_EMAIL: 'Ingrese un correo válido',
    DIGITS_ONLY: 'Ingrese solo números',
    LENGTH: (length) => `Ingrese ${length} caracteres`,
    MIN_LENGTH: (minLength) => `Ingrese como mínimo ${minLength} caracteres`,
    MAX_LENGTH: (maxLength) => `Ingrese como máximo ${maxLength} caracteres`,
  },

  SIDEBAR_ITEMS_PERMISSIONS: [
    {
      name: 'dashboard',
      path: '/dashboard',
      permissions: [],
    },
    {
      name: 'settings',
      path: '/settings',
      permissions: [],
    },
    {
      name: 'projects',
      path: '/projects',
      position: 'projects',
      permissions: [
        'project.post',
        'project.put',
        'project.delete.post',
        'project.list.post',
        'project.list.active.post',
      ],
    },
    {
      name: 'users',
      path: '/users',
      position: 'admin',
      permissions: [
        'user.employee.post',
        'user.employee.put',
        'user.status.post',
        'user.delete.post',
        'user.list.post',
        'user.list.active.post',
      ],
    },
  ],
};
