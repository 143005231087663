import {
  CashIcon,
  ChevronRightIcon,
  ClipboardCheckIcon,
  ClockIcon,
} from '@heroicons/react/solid';
import { calcTasksHours, calcTasksPercentage } from '../../../utils/parseUtils';

export default function SectionItem({
  section,
  costPerHour = 30,
  hoursPerDay = 4,
  projectStatus = 'En cotización',
  onTap,
}) {
  return (
    <li>
      <button
        className="flex items-center justify-between w-full px-6 py-5 text-left transition-all bg-white border-b-4 shadow group sm:rounded-lg hover:bg-gray-50 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-primary-500 focus:outline-none border-primary-500 hover:border-primary-600"
        onClick={onTap}
      >
        <div className="flex-1 max-w-full">
          <h3 className="mb-1 text-base font-medium text-gray-900">
            {section.name}
          </h3>
          <div className="space-y-2">
            <div className="flex items-center text-sm text-gray-500">
              {projectStatus === 'En cotización' ? (
                <>
                  <CashIcon
                    className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                  S/ {calcTasksHours(section.tasks) * costPerHour}
                </>
              ) : (
                <>
                  <ClipboardCheckIcon
                    className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                  {calcTasksPercentage(section.tasks).toFixed(2) || 0} %
                </>
              )}
            </div>

            <div className="flex items-center text-sm text-gray-500">
              <ClockIcon
                className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
              {calcTasksHours(section.tasks)} horas (
              {(calcTasksHours(section.tasks) / hoursPerDay / 5).toFixed(1)}{' '}
              semanas)
            </div>
          </div>
          <p className="mt-1 text-sm text-gray-500">{section.description}</p>
        </div>
        <ChevronRightIcon
          className="flex-shrink-0 w-5 h-5 text-gray-400 group-hover:text-primary-600"
          aria-hidden="true"
        />
      </button>
    </li>
  );
}
