import { Transition } from '@headlessui/react';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useAuth } from '../../contexts/Auth';

import { constants } from '../../utils/constants';
import GeneralApi from '../../utils/generalApi';
import PaginationApi from '../../utils/paginationApi';

import { showErrorToast, showSuccessToast } from '../../components/Toast';
import LoadingSpinner from '../../components/LoadingSpinner';
import SlideOver from '../../components/SlideOver';
import TabItem from '../../components/TabItem';
import ConfirmModalContent from '../../components/ConfirmModalContent';

import UserCard from '../../components/User/UserCard';
import UserCreateForm from '../../components/User/UserCreateForm';
import UserDetails from '../../components/User/UserDetails';
import UserEditForm from '../../components/User/UserEditForm';
import Pagination from '../../components/Pagination';
import Modal from '../../components/Modal';
import { PlusCircleIcon } from '@heroicons/react/solid';

export default function UserList() {
  let history = useHistory();
  let auth = useAuth();

  // User List
  const [loading, setLoading] = useState(true);
  const [users, setUsers] = useState([]);

  // Filters
  const [positionFilter, setPositionFilter] = useState('all');
  const [searchFilter, setSearchFilter] = useState('');

  // Pagination
  const [page, setPage] = useState(1);
  const [maxPage, setMaxPage] = useState(1);
  const [total, setTotal] = useState(0);

  const amountPerPage = 9;

  const generalApi = new GeneralApi(auth, history);
  const usersApi = new PaginationApi(
    '/users/list',
    amountPerPage,
    auth,
    history
  );

  // Slide-overs
  // Create User
  const [isUserCreateOpen, setIsUserCreateOpen] = useState(false);

  // Edit User
  const [isUserEditOpen, setIsUserEditOpen] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState('');

  // User Details
  const [isUserDetailsOpen, setIsUserDetailsOpen] = useState(false);
  const [detailsUser, setDetailsUser] = useState({});

  // Confirm Modals
  // Disable User
  const [isConfirmDisableOpen, setIsConfirmDisableOpen] = useState(false);
  const [loadingToggle, setLoadingToggle] = useState(false);

  // Delete User
  const [isConfirmDeleteOpen, setIsConfirmDeleteOpen] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);

  function setEditUserData(user) {
    setSelectedUserId(user._id);
    setDetailsUser(user);
  }

  function setDetailsUserData(user) {
    setSelectedUserId(user._id);
    setDetailsUser(user);
    setIsUserDetailsOpen(true);
  }

  async function createUser(data) {
    const result = await generalApi.post(`/users/employee`, data);
    if (!result.success) {
      showErrorToast(result.message);
      return result;
    }
    showSuccessToast('Empleado creado');
    setIsUserCreateOpen(false);
    getUsers();
    return result;
  }

  async function updateUser(data) {
    const result = await generalApi.put(`/users/employee`, {
      ...data,
      user: selectedUserId,
    });
    if (!result.success) {
      showErrorToast(result.message);
      return result;
    }
    showSuccessToast('Empleado modificado');
    setIsUserEditOpen(false);
    setSelectedUserId('');
    getUsers();
    return result;
  }

  async function toggleUser(user_id, isActive) {
    setLoadingToggle(true);
    const result = await generalApi.post(`/users/status`, {
      user: user_id,
      isActive,
    });
    if (!result.success) {
      showErrorToast(result.message);
      setLoadingToggle(false);
      return result;
    }
    showSuccessToast(
      isActive ? 'Empleado habilitado' : 'Empleado deshabilitado'
    );
    setIsConfirmDisableOpen(false);
    setIsUserDetailsOpen(false);
    setLoadingToggle(false);
    setSelectedUserId('');
    getUsers();
    return result;
  }

  async function deleteUser(user_id) {
    setLoadingDelete(true);
    const result = await generalApi.post(`/users/delete`, {
      user: user_id,
    });
    if (!result.success) {
      showErrorToast(result.message);
      setLoadingDelete(false);
      return result;
    }
    showSuccessToast('Empleado eliminado');
    setIsConfirmDeleteOpen(false);
    setIsUserDetailsOpen(false);
    setLoadingDelete(false);
    setSelectedUserId('');
    getUsers();
    return result;
  }

  useEffect(() => {
    getUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [positionFilter, searchFilter, page]);

  async function getUsers() {
    setLoading(true);
    let result = await usersApi.getContent(page, {
      search: searchFilter,
      position: positionFilter.split(','),
    });
    if (!result.success) {
      setLoading(false);
      return showErrorToast(result.message);
    }
    setUsers(result.data.users);
    setMaxPage(result.data.pagination.maxPage);
    setTotal(result.data.pagination.total);
    setLoading(false);
  }

  return (
    <div>
      {/* Slide-over Create */}
      <SlideOver
        isOpen={isUserCreateOpen}
        onClose={() => {
          setIsUserCreateOpen(false);
        }}
      >
        <UserCreateForm
          toggleFocus={isUserCreateOpen}
          onSubmit={createUser}
          onCancelClick={() => {
            setIsUserCreateOpen(false);
          }}
        ></UserCreateForm>
      </SlideOver>

      {/* Slide-over Edit */}
      <SlideOver
        isOpen={isUserEditOpen}
        onClose={() => {
          setIsUserEditOpen(false);
        }}
      >
        <UserEditForm
          user={detailsUser}
          toggleFocus={isUserEditOpen}
          onSubmit={updateUser}
          onCancelClick={() => {
            setIsUserEditOpen(false);
          }}
        />
      </SlideOver>

      {/* Slide-over Details */}
      <SlideOver
        isOpen={isUserDetailsOpen}
        onClose={() => {
          setIsUserDetailsOpen(false);
        }}
      >
        <UserDetails
          user={detailsUser}
          toggleFocus={isUserDetailsOpen}
          toggleLoading={loadingToggle}
          onCloseClick={() => {
            setIsUserDetailsOpen(false);
            setSelectedUserId('');
          }}
          onEditClick={(user) => {
            setIsUserDetailsOpen(false);
            setEditUserData(user);
            setIsUserEditOpen(true);
          }}
          onToggleClick={(user) => {
            if (user.isActive) return setIsConfirmDisableOpen(true);
            toggleUser(user._id, true);
          }}
          onDeleteClick={() => {
            setIsConfirmDeleteOpen(true);
          }}
        ></UserDetails>
      </SlideOver>

      <Modal
        isOpen={isConfirmDisableOpen}
        onClose={() => {
          setIsConfirmDisableOpen(false);
        }}
      >
        <ConfirmModalContent
          onClose={() => {
            setIsConfirmDisableOpen(false);
          }}
          onConfirm={() => {
            toggleUser(selectedUserId, false);
          }}
          title="Deshabilitar empleado"
          content="¿Está seguro que desea deshabilitar este empleado? El empleado perderá acceso al sistema."
          buttonLoading={loadingToggle}
        />
      </Modal>

      <Modal
        isOpen={isConfirmDeleteOpen}
        onClose={() => {
          setIsConfirmDeleteOpen(false);
        }}
      >
        <ConfirmModalContent
          onClose={() => {
            setIsConfirmDeleteOpen(false);
          }}
          onConfirm={() => {
            deleteUser(selectedUserId);
          }}
          title="Eliminar empleado"
          content="¿Está seguro que desea eliminar este empleado?"
          buttonLoading={loadingDelete}
        />
      </Modal>

      {/* Header */}
      <div className="px-4 py-4 bg-white shadow sm:pb-0 sm:px-6 lg:px-8">
        <div className="md:flex md:items-center md:justify-between">
          <h1 className="text-2xl font-bold leading-6 text-gray-900 sm:truncate">
            Empleados
          </h1>
          <div className="flex mt-3 right-4 sm:right-6 md:mt-0 md:top-6 lg:right-6">
            <button
              onClick={() => {
                setSelectedUserId('');
                setIsUserCreateOpen(true);
              }}
              type="button"
              className="inline-flex items-center px-4 py-2 text-sm font-medium text-white border border-transparent rounded-md shadow-sm order-0 bg-primary-600 hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:order-1 sm:ml-3"
            >
              Crear empleado
            </button>
          </div>
        </div>
        {/* User Position filter */}
        <div className="mt-4">
          {/* Dropdown menu on small screens */}
          <div className="sm:hidden">
            <label htmlFor="selected-tab" className="sr-only">
              Escoger tipo de empleado
            </label>
            <select
              id="selected-tab"
              name="selected-tab"
              className="block w-full py-2 pl-3 pr-10 text-base border-gray-300 rounded-md focus:outline-none focus:ring-primary-500 focus:border-primary-500 sm:text-sm"
              value={positionFilter}
              onChange={(event) => {
                setPositionFilter(event.target.value);
              }}
            >
              {constants.USER_POSITIONS_FILTER.map((obj, index) => (
                <option key={index} value={obj.value}>
                  {obj.content}
                </option>
              ))}
            </select>
          </div>
          {/* Tabs at small breakpoint and up */}
          <div className="hidden sm:block">
            <nav className="flex -mb-px space-x-8">
              {constants.USER_POSITIONS_FILTER.map((obj, index) => (
                <TabItem
                  key={index}
                  content={obj.content}
                  active={positionFilter === obj.value}
                  onClick={() => {
                    setPositionFilter(obj.value);
                  }}
                ></TabItem>
              ))}
            </nav>
          </div>
        </div>
      </div>

      {/* Users grid */}
      <div className="px-0 mx-auto max-w-7xl sm:px-6 lg:px-8">
        <div className="flex flex-col mt-4">
          <div className="min-w-full align-middle">
            <div className="flex items-center justify-between px-4 py-3 mb-3 bg-white border-t border-gray-200 shadow sm:rounded-lg sm:px-6">
              <div className="w-full max-w-lg lg:max-w-xs">
                <label htmlFor="inp_search" className="sr-only">
                  Buscar
                </label>
                <div className="relative">
                  <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                    {/* Heroicon name: search */}
                    <svg
                      className="w-5 h-5 text-gray-400"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        fillRule="evenodd"
                        d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </div>
                  <input
                    id="inp_search"
                    name="search"
                    className="block w-full py-2 pl-10 pr-3 leading-5 placeholder-gray-500 bg-white border border-gray-300 rounded-md focus:outline-none focus:placeholder-gray-400 focus:ring-1 focus:ring-primary-500 focus:border-primary-500 sm:text-sm"
                    placeholder="Buscar"
                    type="search"
                    value={searchFilter}
                    onChange={(e) => {
                      setSearchFilter(e.target.value);
                    }}
                  ></input>
                </div>
              </div>
            </div>
            <Transition
              show={loading}
              className="flex items-center p-4 bg-white shadow sm:rounded-lg"
            >
              <LoadingSpinner color="primary"></LoadingSpinner>
            </Transition>
            <Transition
              show={!loading}
              as="ul"
              className="grid grid-cols-1 gap-x-6 gap-y-3 sm:grid-cols-2 xl:grid-cols-3"
            >
              <button
                onClick={() => {
                  setSelectedUserId('');
                  setIsUserCreateOpen(true);
                }}
                className="col-span-1 transition-all bg-white shadow cursor-pointer hover:bg-white sm:rounded-lg group ring-offset-2 ring-primary-500 focus:outline-none hover:ring-2 focus:ring-2"
              >
                <PlusCircleIcon
                  className="w-10 h-10 mx-auto text-gray-400 transition-colors group-hover:text-primary-500"
                  aria-hidden="true"
                />
                <span>Crear nuevo empleado</span>
              </button>
              {users.map((obj) => (
                <UserCard
                  key={obj._id}
                  user={obj}
                  searchWords={[searchFilter]}
                  onEditClick={(user) => {
                    setEditUserData(user);
                    setIsUserEditOpen(true);
                  }}
                  onDetailsClick={(user) => {
                    setDetailsUserData(user);
                    setIsUserDetailsOpen(true);
                  }}
                ></UserCard>
              ))}
            </Transition>
            <Transition
              show={!loading && users.length === 0}
              className="flex items-center p-4 mt-3 bg-white shadow sm:rounded-lg"
            >
              <p>No se encontraron resultados</p>
            </Transition>
            {/* Pagination */}
            <Pagination
              page={page}
              amountPerPage={amountPerPage}
              total={total}
              maxPage={maxPage}
              onPreviousPage={() => {
                setPage(page - 1);
              }}
              onNextPage={() => {
                setPage(page + 1);
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
